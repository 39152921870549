import validator from 'validator';

const validatePhoneNumber = async (phoneNumber, apiClient, checkLandline) => {
  if (!validator.isMobilePhone(phoneNumber)) {
    return 'Valid phone number is required';
  }
  if (checkLandline) {
    const phoneNumberTypeResponse = (await apiClient.getPhoneNumberType(phoneNumber)).data;
    if (!phoneNumberTypeResponse || phoneNumberTypeResponse.phoneNumberType === null) {
      return 'Carrier type validation failed.';
    }
    if (phoneNumberTypeResponse.phoneNumberType !== 'mobile') {
      return 'The phone number is listed as a landline. Mobile required.';
    }
  }
  return '';
};

export default validatePhoneNumber;
