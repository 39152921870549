import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { Controls } from './Controls';

function Dropdown(props) {
  const {
    name, value, error, onChange, label, options, disabled,
  } = props;

  return (
    <Controls.Input
      value={value}
      name={name}
      error={error}
      onChange={onChange}
      select
      label={label}
      disabled={disabled}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
      ))}
    </Controls.Input>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

Dropdown.defaultProps = {
  disabled: false,
  error: '',
};

export default Dropdown;
