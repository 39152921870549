import React from 'react';
import './honeycomb';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider as LoggingProvider, ErrorBoundary } from '@rollbar/react';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { SiteSettingsProvider } from './context/SiteSettingsProvider';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_NODE_ENV,
  enabled: ['development', 'staging', 'production'].includes(process.env.REACT_APP_NODE_ENV),
};

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoggingProvider config={rollbarConfig}>
        <ErrorBoundary>
          <AuthProvider>
            <SiteSettingsProvider>
              <Routes>
                <Route
                  path="/*"
                  element={<App />}
                />
              </Routes>
            </SiteSettingsProvider>
          </AuthProvider>
        </ErrorBoundary>
      </LoggingProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
