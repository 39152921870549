import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/controls/Button';
import basicServiceHandler from '../../../services/basicServiceHandler';
import { useApiClient } from '../../../context/ApiClientContext';
import useSiteSettings from '../../../hooks/useSiteSettings';
import { axiosErrorToUserMessage } from '../../../services/axios';

export default function AccuroAutomationButton(props) {
  const { setSuccessMessage, setErrorMessage } = props;
  const apiClient = useApiClient();
  const { activeSiteId } = useSiteSettings();
  const [timeExpired, setTimerExpired] = useState(false);

  const handleForceAccuroAutomation = async () => {
    setTimerExpired(true);
    basicServiceHandler(
      () => apiClient.forceAccuroAutomation(activeSiteId),
      (response) => {
        setSuccessMessage(response.data.message);
        setTimeout(() => { // add 5 seconds delay after successful run to prevent spamming click.
          setTimerExpired(false);
        }, 5000);
      },
      (error) => {
        setErrorMessage(
          axiosErrorToUserMessage(error) || 'Error running Accuro automation',
        );
      },
    );
  };

  return (
    <Button variant="outlined" loading={timeExpired} disabled={timeExpired} text="Run Accuro Automation" onClick={() => handleForceAccuroAutomation()} />
  );
}

AccuroAutomationButton.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
