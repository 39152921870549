import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Controls } from '../../../components/controls/Controls';
import Modal from '../../../components/Modal';
import Form, { useForm } from '../../../components/useForm';
import basicServiceHandler from '../../../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import PointClickCareTable from './PointClickCarePatientTable';

const initialFieldValues = {
  patientName: '',
};

export default function PointClickCareModal(props) {
  const { setSuccessMessage, setErrorMessage, activeSiteId } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patientsFound, setPatientsFound] = useState([]);
  const apiClient = useApiClient();
  const {
    values,
    errors,
    setValues,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const handleSearchPatient = async (e) => {
    e.preventDefault();
    const { patientName } = values;
    if (patientName) {
      setLoading(true);
      basicServiceHandler(
        () => apiClient.searchPointClickCarePatient(
          patientName,
          activeSiteId,
        ),
        (response) => {
          setPatientsFound(response.data);
          if (response.data.length > 0) {
            setSuccessMessage('Patient(s) found');
          } else {
            setErrorMessage('Patient(s) not found');
          }
        },
        (error) => setErrorMessage(convertAxiosErrorToUserFacingMessage(error)),
        () => setLoading(false),
      );
    } else {
      setErrorMessage('Please enter patientId');
    }
  };

  const handleClose = () => {
    setModalVisible(false);
    setErrors({});
    setValues(initialFieldValues);
  };

  return (
    <>
      <Controls.Button
        sx={{ margin: '20px 0 20px 10px' }}
        variant="outlined"
        onClick={() => {
          setModalVisible(true);
        }}
        text="PointClickCare"
      />
      <Modal
        isOpen={modalVisible}
        size="lg"
        handleClose={handleClose}
        title="PointClickCare"
      >
        <Form onSubmit={handleSearchPatient}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            rowSpacing={1}
          >
            <Controls.Input
              label="patient name"
              name="patientName"
              value={values.patientName}
              error={errors.patientName}
              onChange={handleInputChange}
              style={{ maxWidth: '500px' }}
              sx={{
                '& .MuiInputBase-root': {
                  width: '50%',
                },
                '& .MuiFormControl-root': {
                  width: '70%',
                },
              }}
              data-testid="patientSearchBar"
            />
            <Controls.Button
              variant="contained"
              type="submit"
              sx={{ margin: '6px' }}
              loading={loading}
              text="Search Patient"
              data-testid="searchPatientButton"
            />
          </Grid>
        </Form>
        <PointClickCareTable
          patients={patientsFound}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </Modal>
    </>
  );
}

PointClickCareModal.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  activeSiteId: PropTypes.string.isRequired,
};
