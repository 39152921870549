import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../components/controls/Button';

export default function RedirectToPageButton(props) {
  const { buttonText, page } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:375px)');
  const redirect = () => {
    navigate(page);
  };
  return (
    <Button
      text={buttonText}
      onClick={redirect}
      variant="text"
      sx={{
        color: 'black',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        width: 'auto',
        mr: isMobile ? '2px' : '16px',
      }}
    />
  );
}

RedirectToPageButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};
