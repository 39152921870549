import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { Controls } from '../components/controls/Controls';

export default function EditSettingsForm(props) {
  const {
    handleInputChange, values, handleSaveClick,
  } = props;
  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      <Grid item>
        <TextField
          value={values.name}
          name="name"
          label="Name"
          style={{ minWidth: '200px' }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid item>
        <TextField
          label="Category"
          name="category"
          style={{ minWidth: '200px' }}
          variant="outlined"
          value={values.category}
          disabled
        />
      </Grid>
      <Grid item>
        <Controls.Input
          label="Value"
          name="value"
          value={values.value}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item>
        <Controls.Button
          variant="contained"
          sx={{ margin: '6px' }}
          text="Save"
          size="medium"
          onClick={handleSaveClick}
        />
      </Grid>
    </Grid>
  );
}

EditSettingsForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};
