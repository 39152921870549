import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';

import { useApiClient } from '../context/ApiClientContext';

import SpeechToTextService from '../services/speechToTextService';

export default function SpeechToTextButton({
  setTextRecognized,
  disabled,
  sx,
}) {
  const apiClient = useApiClient();
  const sttService = new SpeechToTextService(apiClient);

  const [recognizingSpeech, setRecognizingSpeech] = useState(false);
  const [speechRecognizer, setSpeechRecognizer] = useState(null);

  const enableMicroPhone = async () => {
    const recognizer = await sttService.getSpeechToTextRecognizer('en-US');
    if (recognizer) {
      recognizer.recognized = (s, e) => {
        if (e.result.reason === ResultReason.RecognizedSpeech) {
          setTextRecognized(e.result.text);
        }
      };
      recognizer.startContinuousRecognitionAsync();
      setSpeechRecognizer(recognizer);
      setRecognizingSpeech(true);
    }
  };

  const stopSpeechToTextRecording = () => {
    if (speechRecognizer) {
      setRecognizingSpeech(false);
      speechRecognizer.stopContinuousRecognitionAsync();
    }
  };

  return (
    <Tooltip title="Enable Mic">
      <IconButton
        onMouseDown={(e) => e.preventDefault()}
        onClick={recognizingSpeech ? stopSpeechToTextRecording : enableMicroPhone}
        disabled={disabled}
        sx={sx}
      >
        {recognizingSpeech
          ? (
            <MicOffIcon />
          ) : (
            <MicIcon />
          )}
      </IconButton>
    </Tooltip>
  );
}

SpeechToTextButton.propTypes = {
  setTextRecognized: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  sx: PropTypes.shape({
    position: PropTypes.string,
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    zIndex: PropTypes.number,
  }),
};

SpeechToTextButton.defaultProps = {
  sx: {},
};
