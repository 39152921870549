import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box, CircularProgress,
} from '@mui/material';
import { Controls } from '../components/controls/Controls';
import { sendEmail } from '../services/auth/authServices';

function PasswordRecovery({ toggleView }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await sendEmail(email);
      setEmailSent(true);
      setEmail('');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('An unexpected error occurred.');
      }
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >

      {emailSent ? (
        <Typography variant="subtitle1" sx={{ mt: 3 }}>
          An email has been sent. You can now close this tab.
        </Typography>
      ) : (
        <Box component="form" noValidate={false} sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <Controls.Input
            fullWidth
            label="Email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            error={error}
            sx={{ margin: '10px 0px' }}
            data-testid="email"
          />
          <Controls.Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, width: 1 }}
            text={loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
            disabled={loading}
            data-testid="submitButton"
          />
          <Controls.Button
            type="button"
            fullWidth
            variant="text"
            sx={{ mt: 3, mb: 2, width: 1 }}
            text="Back to Login"
            onClick={toggleView}
          />
        </Box>
      )}
    </Box>
  );
}

PasswordRecovery.propTypes = {
  toggleView: PropTypes.func.isRequired,
};

export default PasswordRecovery;
