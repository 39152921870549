/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import Input from './Input';

function AutocompleteInput({
  options,
  value,
  handleInputChange,
  handleOptionChange,
  ...props
}) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option?.name ?? ''}
      value={value}
      onChange={handleOptionChange}
      freeSolo
      onInputChange={handleInputChange}
      {...props}
      renderInput={(params) => (
        <Input
          {...params}
          {...props}
        />
      )}
    />
  );
}

AutocompleteInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleInputChange: PropTypes.func,
  handleOptionChange: PropTypes.func,
};

export default AutocompleteInput;

AutocompleteInput.defaultProps = {
  handleInputChange: () => {},
  handleOptionChange: () => {},
};
