import React from 'react';
import PropTypes from 'prop-types';
import PointClickCareModal from './PointClickCareModal';

export default function PointClickCareButton(props) {
  const {
    setSuccessMessage,
    setErrorMessage,
    activeSiteId,
  } = props;

  return (
    <PointClickCareModal
      setSuccessMessage={setSuccessMessage}
      setErrorMessage={setErrorMessage}
      activeSiteId={activeSiteId}
    />
  );
}

PointClickCareButton.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeSiteId: PropTypes.string.isRequired,
};
