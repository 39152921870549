import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import UnassignIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from '../../components/controls/Button';
import Modal from '../../components/Modal';
import { useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../../services/axios';

function UnassignUserButton(props) {
  const {
    username, siteId, siteName, fetchUsers, setSuccessMessage, setErrorMessage,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const apiClient = useApiClient();

  const handleConfirmUnassign = async () => {
    if (username) {
      basicServiceHandler(
        () => apiClient.unassignUserFromSiteBySiteId(siteId, username),
        (response) => {
          if (response.status === 200) {
            setSuccessMessage(`${username} unassigned from ${siteName} successfully`);
            fetchUsers();
          } else {
            setErrorMessage(response.data);
          }
        },
        (error) => {
          setErrorMessage(axiosErrorToUserMessage(error));
        },
      );
    }
    setDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Unassign user from site">
        <IconButton onClick={() => setDialogOpen(true)} data-testid="unassignUserIcon">
          <UnassignIcon />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Unassign User"
        data-testid="unassignUserModal"
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          {`Are you sure you want to unassign ${username} from  ${siteName}?`}
        </Typography>
        <Button
          text="Cancel"
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(false)}
          sx={{ marginRight: '1rem' }}
          data-testid="unassignUserCancelButton"
        />
        <Button
          text="Unassign"
          variant="contained"
          color="primary"
          onClick={handleConfirmUnassign}
          data-testid="unassignUserConfirmButton"
        />
      </Modal>
    </>
  );
}

UnassignUserButton.propTypes = {
  username: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default UnassignUserButton;
