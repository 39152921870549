import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid, CircularProgress,
} from '@mui/material';
import { axiosErrorToUserMessage } from '../services/axios';
import SiteSettingsPane from '../content/SiteSettingsPane';
import Toast, { useToast } from '../components/useToast';
import CustomAppBar from '../content/Appbar/CustomAppBar';
import basicServiceHandler from '../services/basicServiceHandler';
import { useApiClient } from '../context/ApiClientContext';
import Sidebar from '../content/SideBar';
import NewSettingForm from '../content/NewSettingForm';
import { useForm } from '../components/useForm';
import Modal from '../components/Modal';
import settingCategories from '../utils/settingCategoriesData';
import SiteTopNavigationBar from './SiteTopNavigationBar';
import useSiteSettings from '../hooks/useSiteSettings';
import usePermission from '../hooks/usePermissions';

const initialFieldValues = {
  name: '',
  category: '',
  value: '',
};

export default function SiteSettingsFrontend() {
  const { siteId } = useParams();

  const { accessibleSiteInfos } = useSiteSettings();
  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newSetting, setNewSetting] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const {
    values, setValues, errors, setErrors, handleInputChange,
  } = useForm(initialFieldValues);
  const apiClient = useApiClient();
  const { isAdmin } = usePermission();

  const {
    toastSetErrorNotification,
    toastSetSuccessNotification,
    toastOnClose,
    toastState,
  } = useToast();

  const handleItemSelect = (item) => {
    setSelectedSubCategory(null);
    if (item.name === 'New Setting') {
      setNewSetting(true);
      return;
    }
    setSelectedItem(item);
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const handleModalClose = () => {
    setNewSetting(false);
    setValues(initialFieldValues);
  };

  const fetchSiteData = () => {
    basicServiceHandler(
      () => apiClient.getSite(siteId),
      (result) => { setData(result.data); },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error fetching site info.',
        );
      },
    );
  };

  const updateSiteData = (name, category, value) => {
    const performSiteSettingOperation = value === ''
      ? () => apiClient.deleteSiteSetting(siteId, name, category)
      : () => apiClient.updateSiteSetting(siteId, { name, category, value });

    basicServiceHandler(
      performSiteSettingOperation,
      () => {
        toastSetSuccessNotification('Site info updated.');
        fetchSiteData();
      },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error updating site info.',
        );
      },
    );
  };

  const validate = async () => {
    const errorMsg = {
      name: values.name ? '' : 'Please enter setting name',
      category: values.category ? '' : 'Please select category',
    };
    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleModalSubmit = async () => {
    if (await validate()) {
      updateSiteData(values.name, values.category, values.value);
      handleModalClose();
    }
  };

  useEffect(() => {
    if (siteId && accessibleSiteInfos.length > 0) {
      fetchSiteData();
    }
  }, [siteId, accessibleSiteInfos]);

  return (
    <Grid container direction="column">
      <Grid item xs={2}>
        <CustomAppBar
          name={data && data.name ? data.name : <CircularProgress size={24} />}
        />
      </Grid>
      <Grid item xs={12}>
        {data && data.name && data.id ? (
          <SiteTopNavigationBar
            isAdmin={isAdmin()}
            siteName={data.name}
            siteId={data.id}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid item xs={10}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={3}>
            <Sidebar
              onItemSelect={handleItemSelect}
              categories={settingCategories}
              selectedItem={selectedItem}
              handleSubCategorySelect={handleSubCategorySelect}
              site={data}
              refreshParent={fetchSiteData}
            />
          </Grid>
          <Grid item xs={9} sx={{ overflow: 'auto' }}>
            {selectedItem && (
              <div>
                <SiteSettingsPane
                  onSave={updateSiteData}
                  category={selectedItem}
                  subCategory={selectedSubCategory}
                  currentSite={data && data}
                  refreshParent={fetchSiteData}
                  setErrorMessage={toastSetErrorNotification}
                  setSuccessMessage={toastSetSuccessNotification}
                />
              </div>
            )}
            <Modal
              isOpen={newSetting}
              handleClose={handleModalClose}
            >
              <Grid container>
                <Grid item xs={6}>
                  <NewSettingForm
                    setValues={setValues}
                    handleInputChange={handleInputChange}
                    values={values}
                    errors={errors}
                    onSubmit={handleModalSubmit}
                    categories={settingCategories}
                    contextType="site"
                  />
                </Grid>
              </Grid>
            </Modal>
            <Toast
              open={toastState.open}
              severity={toastState.severity}
              text={toastState.text}
              onClose={toastOnClose}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
