import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../../../components/controls/Controls';
import AccuroModal from './AccuroModal';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';

export default function AccuroButton(props) {
  const {
    setSuccessMessage,
    setErrorMessage,
    integrationSettings,
    activeSiteId,
  } = props;

  const apiClient = useApiClient();
  const [accuroTokenState, setAccuroTokenState] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    basicServiceHandler(
      () => apiClient.loginAccuro(activeSiteId),
      (response) => { window.location.href = response.data; },
      (error) => setErrorMessage(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  const handleRefresh = async (e) => {
    e.preventDefault();
    basicServiceHandler(
      () => apiClient.refreshAccuroToken(activeSiteId),
      (response) => { if (response.data) { window.location.reload(); } },
      (error) => {
        basicServiceHandler(
          () => apiClient.loginAccuro(activeSiteId),
          (response) => { window.location.href = response.data; },
          (err) => setErrorMessage(convertAxiosErrorToUserFacingMessage(err)),
        );
        setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
      },
    );
  };

  const checkIntegrationSettings = ({
    accessToken,
    refreshToken,
    accessTokenExpireTime,
    refreshTokenExpireTime,
  }) => {
    const currentDate = new Date().getTime();
    const accessTokenExpiry = new Date(accessTokenExpireTime).getTime();
    const refreshTokenExpiry = new Date(refreshTokenExpireTime).getTime();

    const accessTokenExpired = currentDate >= accessTokenExpiry;
    const refreshTokenExpired = currentDate >= refreshTokenExpiry;
    if (
      (!accessToken
      || !refreshToken
      || !accessTokenExpireTime
      || !refreshTokenExpireTime) // tokens are not set in site setting
      // both token are expired => New tokens required
      || (accessTokenExpired && refreshTokenExpired)
    ) {
      return 'login';
    }
    return accessTokenExpired ? 'refresh' : 'enabled';
  };

  useEffect(() => {
    if (integrationSettings) {
      const accuroState = checkIntegrationSettings(integrationSettings);
      setAccuroTokenState(accuroState);
    }
  }, [integrationSettings]);

  switch (accuroTokenState) {
    case 'login':
      return (
        <Controls.Button
          sx={{ margin: '20px 0 20px 10px' }}
          variant="outlined"
          onClick={(e) => {
            handleLogin(e);
          }}
          text="Login Accuro"
        />
      );
    case 'refresh':
      return (
        <Controls.Button
          sx={{ margin: '20px 0 20px 10px' }}
          variant="outlined"
          onClick={(e) => {
            handleRefresh(e);
          }}
          text="Refresh Accuro"
        />
      );
    case 'enabled':
      return (
        <AccuroModal
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          integrationSettings={integrationSettings}
          setAccuroTokenState={setAccuroTokenState}
        />
      );
    default:
      return null;
  }
}

AccuroButton.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integrationSettings: PropTypes.object.isRequired,
  activeSiteId: PropTypes.string.isRequired,
};
