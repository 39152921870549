import { useCallback } from 'react';
import useAuth from './useAuth';

const PERMISSIONS = {
  hxManager: {
    reviewIntake: 'ReviewIntake',
    accuroAccess: 'AllowAccuroAccess',
    createUsersForOwnSites: 'CreateUsersForOwnCustomers',
    viewTraces: 'ReadTraces',
  },
  customer: {
    admin: 'Admin',
    customerAdmin: 'CustomerAdmin',
    accuroAdmin: 'AccuroAdmin',
  },
};

const usePermission = () => {
  const { userPermissions: permissions } = useAuth();

  // this checks for all required permissions like hxManager, c2i, sites, etc
  const hasAllRequiredPermissions = useCallback(
    // Loop through each key in the requiredPermissions object
    (requiredPermissions) => Object.keys(requiredPermissions).every((key) => {
      // If the user's permissions object lacks the key
      // or doesn't contain all required permissions, return false.
      if (
        !permissions[key]
        || !requiredPermissions[key].every((perm) => permissions[key].includes(perm))
      ) {
        return false;
      }

      // If the user has all the required permissions, return true.
      return true;
    }),
    [permissions],
  );

  // this checks if one of permission exist in the permission list of userAccess.
  const hasAnyRequiredPermissions = useCallback(
    // Loop through each key in the requiredPermissions object
    (requiredPermissions) => Object.keys(requiredPermissions).some((key) => {
      // If the user's permissions object lacks the key
      // or doesn't contain required permissions, return false.
      if (
        !permissions[key]
        || !requiredPermissions[key].some((perm) => permissions[key].includes(perm))
      ) {
        return false;
      }

      // If the user has one of required permission return true
      return true;
    }),
    [permissions],
  );

  // this checks just hxManager permissions
  // requiredPermissions is an array of permissions under hxManager app
  const hasHxManagerPermissions = useCallback((requiredPermissions) => {
    if (!permissions.hxManager) {
      return false;
    }
    // If the user has permissions for the hxManager app,
    // iterate through the requiredPermissions array and check if the user
    // has all the necessary permissions.
    return requiredPermissions.every(
      (requiredPermission) => permissions.hxManager.includes(requiredPermission),
    );
  }, [permissions]);

  // Checks for CreateUsersForOwnSites permission (Customer User Admin)
  const canCreateUsersForOwnSites = useCallback(
    () => hasHxManagerPermissions([PERMISSIONS.hxManager.createUsersForOwnSites]),
    [hasHxManagerPermissions],
  );

  const viewTraces = useCallback(
    () => hasHxManagerPermissions([PERMISSIONS.hxManager.viewTraces]),
    [hasHxManagerPermissions],
  );

  const canReviewIntake = useCallback(
    () => hasHxManagerPermissions([PERMISSIONS.hxManager.reviewIntake]),
    [hasHxManagerPermissions],
  );

  const hasAccuroAccess = useCallback(
    () => hasHxManagerPermissions([PERMISSIONS.hxManager.accuroAccess]),
    [hasHxManagerPermissions],
  );

  const hasCustomerPermissions = useCallback((requiredPermissions) => {
    if (!permissions.customer) {
      return false;
    }
    return requiredPermissions.every(
      (requiredPermission) => permissions.customer.includes(requiredPermission),
    );
  }, [permissions]);

  const isAdmin = useCallback(
    () => hasCustomerPermissions([PERMISSIONS.customer.admin]),
    [hasCustomerPermissions],
  );

  const isCustomerAdmin = useCallback( // customerAdmin
    () => hasCustomerPermissions([PERMISSIONS.customer.customerAdmin]),
    [hasCustomerPermissions],
  );

  const isAccuroAdmin = useCallback(
    () => hasCustomerPermissions([PERMISSIONS.customer.accuroAdmin]),
    [hasCustomerPermissions],
  );

  return {
    hasAllRequiredPermissions,
    hasAnyRequiredPermissions,
    hasHxManagerPermissions,
    isAdmin,
    isCustomerAdmin,
    isAccuroAdmin,
    canCreateUsersForOwnSites,
    hasAccuroAccess,
    viewTraces,
    canReviewIntake,
  };
};

export default usePermission;
