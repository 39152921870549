import React, { useState, useEffect } from 'react';
import {
  Autocomplete, MenuItem,
  TextField, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useApiClient } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../../../services/axios';

export default function PatientContactList(props) {
  const {
    setErrorMessage, siteId, patientId,
    handleInputChange, errors,
  } = props;
  const [patientContacts, setPatientContacts] = useState(null);

  const apiClient = useApiClient();

  const fetchPCCPatientContact = async () => {
    const contacts = await basicServiceHandler(
      () => apiClient.getPointClickCarePatientContacts(patientId, siteId),
      (response) => response.data,
      (error) => {
        setErrorMessage(axiosErrorToUserMessage(error));
      },
    );
    const contactList = contacts.flatMap(({
      firstName, lastName, cellPhone, homePhone, officePhone,
    }) => {
      const contactTypes = [
        { number: cellPhone, type: 'mobile' },
        { number: homePhone, type: 'home' },
        { number: officePhone, type: 'office' },
      ];

      return contactTypes
        .filter((contact) => contact.number)
        .map((contact) => ({ firstName, lastName, ...contact }));
    });
    setPatientContacts(contactList);
    return contacts;
  };

  useEffect(() => {
    if (!patientContacts && patientId && siteId) {
      fetchPCCPatientContact();
    }
  }, [patientId, siteId, patientContacts]);

  const setPhoneNumberValue = (e) => {
    const phoneNumberObject = {
      target: {
        name: 'phoneNumber',
        value: e.target.getAttribute('value'),
      },
    };
    handleInputChange(phoneNumberObject);
  };

  return (
    <Autocomplete
      options={patientContacts ?? []}
      getOptionLabel={(option) => `${option.number}`}
      groupBy={(option) => `${option.firstName} ${option.lastName}`}
      renderOption={(prop, option) => {
        const { number, type, firstName } = option;
        return (
          <MenuItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...prop}
            key={`${number}:${type}:${firstName}`}
            name="phoneNumber"
            value={number}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {number}
            <Typography>
              {type}
            </Typography>
          </MenuItem>
        );
      }}
      onChange={setPhoneNumberValue}
      freeSolo
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Patient phone number"
          name="phoneNumber"
          error={errors}
          helperText={errors}
          onChange={handleInputChange}
        />
      )}
    />
  );
}

PatientContactList.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

PatientContactList.defaultProps = {
  errors: '',
};
