import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  DialogActions,
  Typography,
  Modal as MuiModal,
  List,
  ListItemText,
  Checkbox,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useApiClient } from '../../context/ApiClientContext';
import Toast, { useToast } from '../../components/useToast';
import { axiosErrorToUserMessage } from '../../services/axios';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  borderRadius: '8px',
  boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.1)',
  p: 4,
};

function AssignUsersToSiteModal({
  assignedUsers,
  isOpen,
  isAdmin,
  onClose,
  siteId,
  shouldFetchUsers,
  fetchUsers,
}) {
  const [usersList, setUsersList] = useState([]);
  const [toBeAssignedUsers, setToBeAssignedUser] = useState([]);
  const apiClient = useApiClient();
  const {
    toastSetErrorNotification,
    toastSetSuccessNotification,
    toastState,
    toastOnClose,
  } = useToast();

  const getUniqueUsernames = (users) => Array.from(new Set(users.map((user) => user.username)));

  const getUsers = useCallback(async () => {
    if (!shouldFetchUsers) return Promise.resolve();
    let isComponentMounted = true;
    try {
      let users;
      if (isAdmin) {
        const userListResponse = await apiClient.getAllUsers();
        users = userListResponse.data;
      } else {
        const customers = await apiClient.getAssignedCustomers();
        const customerIds = customers.data.map((customer) => customer.id);
        const responses = await Promise.all(
          customerIds.map((customerId) => apiClient.getUsersByCustomerId(customerId)),
        );
        users = responses.flatMap((response) => response.data);
        users = users.filter((user) => !user.roles.includes('SuperAdmin') && !user.roles.includes('CustomerAdmin'));
      }
      const uniqueUsernames = getUniqueUsernames(users);
      if (isComponentMounted) {
        setUsersList(uniqueUsernames);
      }
    } catch (error) {
      if (isComponentMounted) {
        setUsersList([]);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [shouldFetchUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleAddUser = async () => {
    if (toBeAssignedUsers.length > 0) {
      try {
        const response = await apiClient.assignUsersToSiteBySiteId(siteId, toBeAssignedUsers);
        if (response.data) {
          setToBeAssignedUser([]); // clear list
          toastSetSuccessNotification('User successfully assigned to this site.');
          fetchUsers();
        }
      } catch (error) {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error adding user to this site.',
        );
      }
    } else {
      toastSetErrorNotification('Please select a user to assign to this site');
    }
  };

  const addToAssignedUserList = (username) => {
    setToBeAssignedUser((prevList) => {
      if (prevList.includes(username)) {
        return prevList.filter((name) => name !== username);
      }
      return [...prevList, username];
    });
  };

  const isAssigned = (user) => getUniqueUsernames(assignedUsers).includes(user);

  const isChecked = (user) => isAssigned(user) || toBeAssignedUsers.includes(user);

  return (
    <>
      <MuiModal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="assign-users-to-site-modal"
        aria-describedby="assign-users-to-site-modal"
        data-testid="assign-users-modal"
      >
        <Box sx={modalStyle}>
          <Typography
            id="assign-users-to-site-modal"
            variant="h6"
            component="h2"
            sx={{ marginBottom: '16px' }}
          >
            Select users to assign to site
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              height: '500px',
              overflow: 'auto',
            }}
          >
            <List>
              {usersList.map((user) => (
                <ListItemButton key={user} onClick={() => addToAssignedUserList(user)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isChecked(user)}
                      disabled={isAssigned(user)}
                    />
                  </ListItemIcon>
                  <ListItemText id={user} primary={user} />
                </ListItemButton>
              ))}
            </List>
          </Box>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => handleAddUser()} variant="contained" color="primary">
              Add
            </Button>
          </DialogActions>
        </Box>
      </MuiModal>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
        autoHideDuration={5000}
      />
    </>
  );
}

AssignUsersToSiteModal.propTypes = {
  assignedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string)
        .isRequired,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  shouldFetchUsers: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func.isRequired,
};

export default AssignUsersToSiteModal;
