import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Grid,
  MenuItem,
} from '@mui/material';
import Modal from '../components/Modal';
import { Controls } from '../components/controls/Controls';
import { useApiClient } from '../context/ApiClientContext';
import basicServiceHandler from '../services/basicServiceHandler';
import { useToast } from '../components/useToast';
import { axiosErrorToUserMessage } from '../services/axios';

export default function CustomerCopySettingsModal(props) {
  const {
    isOpen,
    handleClose,
    refreshParent,
  } = props;

  const { customerId } = useParams();
  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();
  const apiClient = useApiClient();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const fetchCustomerData = () => {
    basicServiceHandler(
      () => apiClient.getAllCustomers(),
      (result) => {
        const otherCustomers = result.data.filter((customer) => customer.id !== customerId);
        setCustomers(otherCustomers);
      },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error fetching sites.',
        );
      },
    );
  };

  const handleCopySetting = () => {
    basicServiceHandler(
      () => apiClient.copyCustomerSettings(selectedCustomer.id, customerId),
      (result) => {
        toastSetSuccessNotification(
          result.data,
        );
        refreshParent();
        handleClose();
      },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error fetching sites.',
        );
      },
    );
  };

  const handleSelectChange = (event) => {
    setSelectedCustomer(event.target.value);
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="sm"
      title="Copy settings from another customer"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        rowSpacing={1}
      >
        <Controls.Select
          sx={{ minWidth: '200px', maxWidth: '400px' }}
          label="Value"
          name="value"
          value={selectedCustomer}
          onChange={handleSelectChange}
          renderValue={() => selectedCustomer.name}
        >
          {customers.map((option) => (
            <MenuItem key={option.name} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </Controls.Select>
        <Controls.Button
          text="Copy"
          onClick={handleCopySetting}
        />
      </Grid>
    </Modal>
  );
}

CustomerCopySettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
