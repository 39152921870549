import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { axiosCustomerInstance } from '../services/axios';
import Toast, { useToast } from '../components/useToast';
import CreateNewCustomerButton from './CreateNewCustomerButton';
import CustomerInfoTable from './CustomerInfoTable';
import CustomAppBar from '../content/Appbar/CustomAppBar';

export default function CustomerHome() {
  const [customerData, setCustomerData] = useState(null);
  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();
  const axiosCustomerPrivate = useAxiosPrivate(axiosCustomerInstance);

  const fetchCustomers = async () => {
    try {
      const response = await axiosCustomerPrivate.get('/customer/getAllCustomers');
      setCustomerData(response.data);
      toastSetSuccessNotification('Successfully fetched customer data.');
    } catch (error) {
      toastSetErrorNotification('Error fetching customer data.');
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container>
        <Grid sx={{ width: 1 }}>
          <CustomAppBar
            siteSelectorOn={false}
            name="Customer Settings Page"
          />
        </Grid>
        <Grid sx={{ width: 1, padding: '10px 30px' }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
          >
            <CreateNewCustomerButton
              refreshParent={fetchCustomers}
              setSuccessMessage={toastSetSuccessNotification}
              setErrorMessage={toastSetErrorNotification}
            />
          </Box>
          {customerData && (
            <CustomerInfoTable
              data={customerData}
              setErrorMessage={toastSetErrorNotification}
              setSuccessMessage={toastSetSuccessNotification}
              refreshParent={fetchCustomers}
            />
          )}
          <Toast
            open={toastState.open}
            severity={toastState.severity}
            text={toastState.text}
            onClose={toastOnClose}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
