import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Controls } from '../controls/Controls';
import { useApiClient } from '../../context/ApiClientContext';

export default function LogoUploadSetting({
  setting, setValues, refreshParent, setErrorMessage, setSuccessMessage, contextType,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { siteId, customerId } = useParams();
  const apiClient = useApiClient();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setErrorMessage('No file selected');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await apiClient.uploadLogo(formData);
      const updatedSetting = { ...setting, value: response.data.logoUrl };

      if (contextType === 'customer') {
        await apiClient.updateCustomer(customerId, updatedSetting);
      } else {
        await apiClient.updateSiteSetting(siteId, updatedSetting);
      }

      setValues(updatedSetting);
      setSuccessMessage('Logo uploaded successfully');
      refreshParent();
    } catch (error) {
      setErrorMessage(`Error uploading file: ${error.response?.data.message || 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {setting.value && (
        <div style={{ marginTop: '10px' }}>
          <img src={setting.value} alt="Uploaded Logo" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )}
      <Controls.Input
        type="file"
        onChange={handleFileChange}
        disabled={isLoading}
      />
      <Controls.Button
        text={isLoading ? 'Uploading...' : 'Upload'}
        onClick={handleUpload}
        disabled={isLoading}
      />
    </div>
  );
}

LogoUploadSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  refreshParent: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
};

LogoUploadSetting.defaultProps = {
  refreshParent: () => {},
  setErrorMessage: () => {},
  setSuccessMessage: () => {},
};
