import React, { useEffect, useState } from 'react';
import {
  Grid, Box,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SendIntakeButton from '../content/SendIntakeButton';
import IntakeInfoTable from '../content/IntakeInfoTable/IntakeInfoTable';
import useSiteSettings from '../hooks/useSiteSettings';
import Toast, { useToast } from '../components/useToast';
import AccuroButton from '../content/integrations/Accuro/AccuroButton';
import CustomAppBar from '../content/Appbar/CustomAppBar';
import { IntakeDataProvider } from '../context/IntakeDataContext';
import QRCodeButton from '../content/QRCodeButton';
import SearchBar from '../components/SearchBar';
import { useApiClient } from '../context/ApiClientContext';
import basicServiceHandler from '../services/basicServiceHandler';
import PointClickCareButton from '../content/integrations/PointClickCare/PointClickCareButton';

export default function Home() {
  const [integrationSettings, setIntegrationSetting] = useState(null);
  const [siteSettings, setSiteSettings] = useState(null);
  const [showAppointmentField, setShowAppointmentField] = useState(false);
  const { activeSiteId, accessibleSiteInfos, showAppointmentDatesForSite } = useSiteSettings();
  const isMobile = useMediaQuery('(max-width:425px)');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const apiClient = useApiClient();

  const getSiteSettings = () => {
    const filteredActiveSiteInfos = accessibleSiteInfos
      .filter((item) => item.id === activeSiteId);

    if (filteredActiveSiteInfos.length > 0) {
      const activeSiteSettings = filteredActiveSiteInfos[0].settings;
      const integrationSettingsFromDB = {
        pushToEndpointEnabled: activeSiteSettings.find((setting) => setting.name === 'pushToEndpointEnabled')?.value,
        integrationType: activeSiteSettings.find((setting) => setting.name === 'pushToEndpointIntegrationType')?.value,
        accessToken: activeSiteSettings.find((setting) => setting.name === 'accuroAccessToken')?.value,
        refreshToken: activeSiteSettings.find((setting) => setting.name === 'accuroRefreshToken')?.value,
        accuroEnabled: activeSiteSettings.find((setting) => setting.name === 'accuroEnabled')?.value,
        accessTokenExpireTime: activeSiteSettings.find((setting) => setting.name === 'accuroAccessTokenExpireTime')?.value,
        refreshTokenExpireTime: activeSiteSettings.find((setting) => setting.name === 'accuroRefreshTokenExpireTime')?.value,
        pccEnabled: activeSiteSettings.find((setting) => setting.name === 'pccEnabled')?.value,
      };
      const otherSettingsFromDB = {
        qrCodeIntakePlan: activeSiteSettings.find((setting) => setting.name === 'qrCodeIntakePlan')?.value,
        languageOptions: activeSiteSettings.find((setting) => setting.name === 'languageOptions')?.value,
        skipCaptcha: activeSiteSettings.find((setting) => setting.name === 'skipCaptcha')?.value,
      };
      setIntegrationSetting(integrationSettingsFromDB);
      setSiteSettings(otherSettingsFromDB);
    }
  };

  useEffect(() => {
    if (activeSiteId && accessibleSiteInfos.length > 0) {
      getSiteSettings();
    }
  }, [activeSiteId, accessibleSiteInfos]);

  useEffect(() => {
    setShowAppointmentField(showAppointmentDatesForSite(activeSiteId));
  }, [accessibleSiteInfos, activeSiteId, showAppointmentDatesForSite]);

  const handleSearch = () => {
    setLoading(true);
    basicServiceHandler(
      () => apiClient.searchIntakesByPatientIdPrefix(activeSiteId, searchValue),
      (response) => {
        if (response.data.length === 0) {
          toastSetErrorNotification(`Unable to find any patient ID starting with "${searchValue}"`);
        }
        setSearchResults(response?.data || []);
        setLoading(false);
      },
      () => {
        toastSetErrorNotification('Error fetching search results');
        setLoading(false);
      },
    );
  };

  // automatically search DB if searvhValue is 3 characters long.
  // We can improve search result by adding more characters in the future
  useEffect(() => {
    if (searchValue.length === 3) {
      handleSearch();
    } else if (searchValue.length <= 2) {
      // clear search result so intake table will display all intakes
      setSearchResults([]);
    }
  }, [searchValue]);

  const isAccuroEnabled = integrationSettings?.accuroEnabled === 'true';
  const isPCCEnabled = integrationSettings?.pccEnabled === 'true';
  const showQRCode = siteSettings?.qrCodeIntakePlan && siteSettings.qrCodeIntakePlan !== '';

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };

  return (
    <>
      <CustomAppBar
        siteSelectorOn
      />
      <IntakeDataProvider>
        <Grid
          sx={{
            width: 1,
            padding: '0 30px',
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: isMobile ? 'column-reverse' : 'row',
            }}
          >
            <SearchBar
              value={searchValue}
              onChange={handleSearchInputChange}
              placeholder="Enter by patient ID"
              loading={loading}
            />
            <Box
              sx={{
                width: isMobile ? '100%' : 'auto',
              }}
            >
              {!!activeSiteId && (// TODO: handle site access in a centralized location
                <>
                  <SendIntakeButton
                    setSuccessMessage={toastSetSuccessNotification}
                    setErrorMessage={toastSetErrorNotification}
                    showAppointmentField={showAppointmentField}
                  />
                  {showQRCode
                  && (
                  <QRCodeButton
                    siteId={activeSiteId}
                    languageOptions={siteSettings?.languageOptions}
                    skipCaptcha={siteSettings?.skipCaptcha}
                  />
                  )}
                </>
              )}
              {isAccuroEnabled && (
                <AccuroButton
                  setSuccessMessage={toastSetSuccessNotification}
                  setErrorMessage={toastSetErrorNotification}
                  integrationSettings={integrationSettings}
                  activeSiteId={activeSiteId}
                />
              )}
              {isPCCEnabled && (
                <PointClickCareButton
                  setSuccessMessage={toastSetSuccessNotification}
                  setErrorMessage={toastSetErrorNotification}
                  activeSiteId={activeSiteId}
                />
              )}
            </Box>
          </Box>
          {integrationSettings && (
            <IntakeInfoTable
              setSuccessMessage={toastSetSuccessNotification}
              setErrorMessage={toastSetErrorNotification}
              integrationSettings={integrationSettings}
              searchResults={searchResults}
              showAppointmentField={showAppointmentField}
            />
          )}
          <Toast
            open={toastState.open}
            severity={toastState.severity}
            text={toastState.text}
            onClose={toastOnClose}
          />
        </Grid>
      </IntakeDataProvider>
    </>
  );
}
