import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../components/controls/Controls';
import SendIntakeModal from './SendIntakeModal';

export default function SendIntakeButton(props) {
  const {
    setSuccessMessage,
    setErrorMessage,
    title,
    value,
    patient,
    integrationType,
    showAppointmentField,
  } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Controls.Button
        sx={{ margin: '10px 0', padding: '10px' }}
        onClick={() => {
          setModalVisible(true);
        }}
        text={title}
        data-testid="newPatientIntakeButton"
      />
      <SendIntakeModal
        modalVisible={modalVisible}
        handleClose={handleClose}
        value={value}
        patient={patient}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
        integrationType={integrationType}
        showAppointmentField={showAppointmentField}
      />
    </>
  );
}

SendIntakeButton.propTypes = {
  title: PropTypes.string,
  integrationType: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  showAppointmentField: PropTypes.bool.isRequired,
};

SendIntakeButton.defaultProps = {
  title: '+ New Intake',
  value: '',
  integrationType: null,
  patient: null,
};
