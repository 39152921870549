import React, { useState } from 'react';
import PropTypes from 'prop-types';
import basicServiceHandler from '../services/basicServiceHandler';
import Form, { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import Modal from '../components/Modal';
import { axiosErrorToUserMessage, axiosCustomerInstance } from '../services/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const initialFieldValues = {
  name: '',
  siteIds: [],
};

export default function CreateNewCustomerButton(props) {
  const {
    refreshParent,
    setSuccessMessage,
    setErrorMessage,
  } = props;

  const [open, setOpen] = useState(false);

  const axiosCustomerPrivate = useAxiosPrivate(axiosCustomerInstance);

  const {
    values,
    handleInputChange, errors, setErrors,
  } = useForm(initialFieldValues);

  const handleClose = () => {
    setOpen(false);
  };

  const createCustomer = async () => {
    const payload = {
      ...values,
    };

    basicServiceHandler(
      () => axiosCustomerPrivate.post('/customer/', payload),
      (response) => {
        setSuccessMessage('Customer created successfully.');
        refreshParent();
        return response.data.id;
      },
      (error) => {
        setErrorMessage(
          axiosErrorToUserMessage(error),
        );
      },
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    errors.name = values.name ? '' : 'Name is required';
    setErrors({ ...errors });

    if (!values.name) {
      setErrorMessage('Please enter a customer name');
      return;
    }

    // If all fields are valid, proceed with creating a new customer
    createCustomer();
    handleClose();
  };

  return (
    <>
      <Controls.Button
        text="Create New Customer"
        onClick={() => setOpen(true)}
        data-testid="create-new-customer-button"
      />
      <Modal
        isOpen={open}
        handleClose={handleClose}
        title="Create New Customer"
        data-testid="create-new-customer-modal"
      >
        <Form data-testid="create-customer-form">
          <Controls.Input
            name="name"
            label="Customer Name"
            value={values.name}
            onChange={handleInputChange}
            data-testid="customer-name-input"
          />
          <Controls.Button
            text="Submit"
            onClick={handleSubmit}
            data-testid="submit-button"
          />
        </Form>
      </Modal>
    </>
  );
}

CreateNewCustomerButton.propTypes = {
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
