import React, { useEffect, useState } from 'react';
import { Grid, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from '../components/useForm';
import Modal from '../components/Modal';
import EditSettingsForm from './EditSettingsForm';
import EditSettingsOptions from './EditSettingsOptions';
import ResetSettingButton from './ResetSettingButton';
import { Controls } from '../components/controls/Controls';
import defaultUnsetSettings from '../site/DefaultUnsetSettings';

const initialFieldValues = {
  name: '',
  category: '',
  value: '',
};

export default function SiteSettingsPane(props) {
  const {
    onSave, category, subCategory, currentSite, refreshParent, setErrorMessage, setSuccessMessage,
  } = props;
  const [mergedSettingsForCategory, setMergedSettingsForCategory] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [unsetSettings, setUnsetSettings] = useState([]);

  const {
    values, setValues, handleInputChange,
  } = useForm(initialFieldValues);

  const handleModalClose = () => {
    setIsEditing(false);
    setValues(initialFieldValues);
  };

  const handleSaveClick = () => {
    if (!values.name.trim() || !values.category.trim()) {
      setErrorMessage('Both name and category must be filled out.');
      return;
    }

    setIsEditing(false);
    onSave(values.name, values.category, values.value);
    setValues(initialFieldValues);
  };

  const getMergedSettingsForCategory = () => {
    if (!currentSite.settings) {
      return [];
    }

    const filterByCategory = (setting) => setting.category === category.id
        && (!subCategory || setting.name.includes(subCategory.id));

    const settings = currentSite.settings.filter(filterByCategory);
    const defaultSettings = currentSite.defaultSettings.filter(filterByCategory);

    const mergedSettings = [];

    // Add custom settings and mark them as default or custom
    settings.forEach((setting) => {
      const commonSettings = defaultSettings.find((
        defSetting,
      ) => defSetting.name === setting.name);
      const isDefault = commonSettings && commonSettings.value === setting.value;
      mergedSettings.push({ ...setting, isDefault });
    });

    // Add any default settings not already in the custom settings
    defaultSettings.forEach((defSetting) => {
      if (!settings.some((setting) => setting.name === defSetting.name)) {
        mergedSettings.push({ ...defSetting, isDefault: true });
      }
    });

    return mergedSettings;
  };

  const getUnsetSettings = (settings) => {
    if (category.subCategories) {
      if (!subCategory) {
        return [];
      }
      const allSettingsForCategory = defaultUnsetSettings[category.id];
      const setSettingsNames = settings.map((setting) => setting.name);
      return (allSettingsForCategory.filter(
        (setting) => !setSettingsNames.includes(setting) && setting.includes(subCategory.id),
      ));
    }
    const allSettingsForCategory = defaultUnsetSettings[category.id];
    const setSettingsNames = settings.map((setting) => setting.name);
    return (allSettingsForCategory.filter(
      (setting) => !setSettingsNames.includes(setting),
    ));
  };

  useEffect(() => {
    if (currentSite) {
      const mergedSettings = getMergedSettingsForCategory();
      setMergedSettingsForCategory(mergedSettings);
      setUnsetSettings(getUnsetSettings(mergedSettings));
    }
  }, [category, currentSite, subCategory]);

  if (!currentSite) {
    return null;
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <div style={{ width: '100%' }}>
        {mergedSettingsForCategory.map((setting) => {
          const { isDefault, ...settingValues } = setting;

          return (
            <Grid container alignItems="center" spacing={2} key={setting.name} style={{ marginBottom: '30px', marginTop: '10px' }}>
              <Grid item>
                <Controls.Input
                  value={setting.name}
                  name="name"
                  label="Name"
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item>
                <EditSettingsOptions
                  setValues={setValues}
                  handleSaveClick={handleSaveClick}
                  handleEditClick={() => {
                    setIsEditing(true);
                    setValues(settingValues);
                  }}
                  values={settingValues}
                  refreshParent={refreshParent}
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                  handleInputChange={handleInputChange}
                  contextType="site"
                />
              </Grid>
              <Grid item>
                {setting.isDefault
                  ? (
                    <Chip label="Default Setting" size="small" />
                  )
                  : (
                    <ResetSettingButton
                      entity={currentSite}
                      type="site"
                      setting={setting}
                      refreshParent={refreshParent}
                      setErrorMessage={setErrorMessage}
                      setSuccessMessage={setSuccessMessage}
                    />
                  )}

              </Grid>
            </Grid>
          );
        })}
        {unsetSettings.map((setting) => (
          <Grid container spacing={2} key={setting} style={{ marginBottom: '30px', marginTop: '10px' }}>
            <Grid item>
              <Controls.Input
                value={setting}
                name="name"
                label="Name"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item>
              <EditSettingsOptions
                setValues={setValues}
                handleSaveClick={handleSaveClick}
                handleEditClick={() => {
                  setIsEditing(true);
                  setValues({ name: setting, category: category.id, value: values.value || '' });
                }}
                values={{ name: setting, category: category.id, value: '' }}
                refreshParent={refreshParent}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                handleInputChange={handleInputChange}
                contextType="site"
              />
            </Grid>
          </Grid>
        ))}
      </div>
      <Modal isOpen={isEditing} handleClose={handleModalClose}>
        <Grid container>
          <Grid item xs={6}>
            <EditSettingsForm
              handleInputChange={handleInputChange}
              settingsForCategory={mergedSettingsForCategory}
              values={values}
              handleSaveClick={handleSaveClick}
            />
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

const settingShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

SiteSettingsPane.propTypes = {
  onSave: PropTypes.func.isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  currentSite: PropTypes.shape({
    settings: PropTypes.arrayOf(settingShape).isRequired,
    defaultSettings: PropTypes.arrayOf(settingShape).isRequired,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  subCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

SiteSettingsPane.defaultProps = {
  subCategory: null,
};
