import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Controls } from '../controls/Controls';
import basicServiceHandler from '../../services/basicServiceHandler';
import { useApiClient } from '../../context/ApiClientContext';

// Helper function to update field border color
const updateFieldColor = (setFieldColor, color) => {
  setFieldColor({
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
      borderWidth: '2px',
    },
  });
};

export default function TextFieldSetting({
  setting, setValues, refreshParent, isCreateMode, contextType,
}) {
  const apiClient = useApiClient();
  const [fieldValue, setFieldValue] = useState(setting.value);
  const [fieldColor, setFieldColor] = useState({});
  const { siteId, customerId } = useParams();

  const handleChange = (event) => {
    setFieldColor({});
    setFieldValue(event.target.value);
  };

  const handleSuccess = () => {
    refreshParent();
    updateFieldColor(setFieldColor, 'green');
  };

  const handleFailure = () => {
    updateFieldColor(setFieldColor, 'red');
  };

  const handleBlur = () => {
    const { name, category } = setting;
    if (isCreateMode) {
      setValues({
        name: setting.name,
        category: setting.category,
        value: fieldValue,
        siteId,
      });
    } else {
      let handleSettingUpdate;
      if (contextType === 'customer') {
        if (fieldValue === '') {
          // Delete customer setting
          handleSettingUpdate = () => apiClient.deleteCustomerSetting(customerId, name, category);
        } else {
          // Update customer setting
          handleSettingUpdate = () => apiClient.updateCustomer(
            customerId,
            { ...setting, value: fieldValue },
          );
        }
      } else if (fieldValue === '') {
        // Delete site setting
        handleSettingUpdate = () => apiClient.deleteSiteSetting(siteId, name, category);
      } else {
        // Update site setting
        handleSettingUpdate = () => apiClient.updateSiteSetting(
          siteId,
          { ...setting, value: fieldValue },
        );
      }
      // if in update mode, we want to be directly updating the backend with new values
      basicServiceHandler(
        handleSettingUpdate,
        handleSuccess,
        handleFailure,
      );
    }
  };

  useEffect(() => {
    setFieldValue(setting.value);
  }, [setting, siteId, contextType]);

  return (
    <Controls.Input
      label="Value"
      name="value"
      value={fieldValue ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      sx={fieldColor}
    />
  );
}

TextFieldSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
  setValues: PropTypes.func.isRequired,
  refreshParent: PropTypes.func,
  isCreateMode: PropTypes.bool,
};

TextFieldSetting.defaultProps = {
  refreshParent: null,
  isCreateMode: false,
};
