import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Controls } from '../components/controls/Controls';
import Dropdown from '../components/controls/Dropdown';
import EditSettingsOptions from './EditSettingsOptions';

export default function NewSettingForm(props) {
  const {
    categories, handleInputChange, values, errors, onSubmit, setValues, contextType,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controls.Input
          label="Name"
          name="name"
          value={values.name}
          error={errors.name}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          value={values.category}
          error={errors.category}
          name="category"
          onChange={handleInputChange}
          label="Category"
          options={categories}
        />
      </Grid>

      <Grid item xs={12}>
        <EditSettingsOptions
          setValues={setValues}
          values={values}
          handleInputChange={handleInputChange}
          handleSaveClick={onSubmit}
          newSetting
          isCreateMode
          contextType={contextType}
        />
      </Grid>
      <Grid container justifyContent="flex-end">
        <Controls.Button
          variant="contained"
          type="submit"
          sx={{ margin: '6px' }}
          text="Submit"
          size="medium"
          onClick={onSubmit}
        />
      </Grid>
    </Grid>
  );
}

NewSettingForm.propTypes = {
  setValues: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
};
