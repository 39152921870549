import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { BottomNavigationAction, Typography, Box } from '@mui/material';
import Modal from '../components/Modal';
import { Controls } from '../components/controls/Controls';
import { useApiClient } from '../context/ApiClientContext';

export default function PatientSupportEmailButton({
  template,
  index,
  patientSupportEmailRecipient,
  activeSiteId,
  onError,
  onSuccess,
  axiosError,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSendingTemplate, setIsSendingTemplate] = useState(false);
  const apiClient = useApiClient();
  const theme = useTheme();

  const handleSendTemplateEmail = async (templateId) => {
    setIsSendingTemplate(true);
    try {
      await apiClient.sendPatientSupportEmail({
        siteId: activeSiteId,
        supportTemplate: templateId,
      });
      onSuccess('Email sent.');
      setDialogOpen(false);
    } catch (error) {
      onError(
        axiosError(error)
          || 'An error occurred when sending report email.',
      );
    } finally {
      setIsSendingTemplate(false);
    }
  };

  return (
    <>
      <BottomNavigationAction
        label={`Template ${index + 1}`}
        onClick={() => setDialogOpen(true)}
        sx={{
          '&.MuiBottomNavigationAction-root': {
            minWidth: '144px',
            display: 'flex',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '16px',
            height: 'fit-content',
            fontWeight: 600,
            p: 1,
            '&:hover': {
              backgroundColor: '#174b84',
              color: theme.palette.common.white,
            },
          },
          '& .MuiBottomNavigationAction-label': { // Notice the space indicating descendant combinator
            opacity: '1',
          },
        }}
      />
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title={`Send email to: ${patientSupportEmailRecipient}`}
        aria-labelledby="modal-modal-title"
      >
        <Typography variant="subtitle2" sx={{ mt: 2 }} data-testid="modal-subject">
          {`Subject: ${template.subject}`}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }} data-testid="modal-content">
          {template.plainContent}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            mb: 1,
          }}
        >
          <Controls.Button
            onClick={() => handleSendTemplateEmail(template.templateId)}
            text="Confirm"
            loading={isSendingTemplate}
            data-testid="email-confirmButton"
          />
          <Controls.Button
            variant="outlined"
            onClick={() => setDialogOpen(false)}
            text="Cancel"
            data-testid="email-cancelButton"
          />
        </Box>
      </Modal>
    </>
  );
}

PatientSupportEmailButton.propTypes = {
  index: PropTypes.number.isRequired,
  template: PropTypes.shape({
    templateId: PropTypes.string.isRequired,
    subject: PropTypes.string,
    plainContent: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  axiosError: PropTypes.func.isRequired,
  patientSupportEmailRecipient: PropTypes.string.isRequired,
};
