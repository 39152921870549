import React from 'react';

import PropTypes from 'prop-types';
import PublishIcon from '@mui/icons-material/Publish';
import { axiosErrorToUserMessage } from '../../../services/axios';
import useSiteSettings from '../../../hooks/useSiteSettings';
import Button from '../../../components/controls/Button';
import { useApiClient } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';
import { useIntakeData } from '../../../context/IntakeDataContext';

function PushAccuroButton(props) {
  const {
    mkey, patientId, setSuccessMessage, setErrorMessage,
  } = props;
  const { activeSiteId } = useSiteSettings();
  const apiClient = useApiClient();
  const { fetchIntakeData } = useIntakeData();
  if (!activeSiteId) return null;

  const handleAccuroPush = async () => {
    // get latest access Token from site setting using activeSiteId
    const identifiableData = {
      integrationType: 'accuro',
      patientId,
    };
    basicServiceHandler(
      () => apiClient.handleAccuroPush(mkey, identifiableData, activeSiteId),
      (response) => {
        if (response.data.integrationSuccess) {
          fetchIntakeData();
          setSuccessMessage('Successfully pushed accuro');
          // TODO: mark intake completed
        }
      },
      (error) => {
        setErrorMessage(
          axiosErrorToUserMessage(error) || 'Error pushing Accuro',
        );
      },
    );
  };

  return (
    <Button
      text="EMR"
      variant="outlined"
      size="small"
      startIcon={<PublishIcon fontSize="small" />}
      onClick={() => handleAccuroPush()}
    />
  );
}
export default PushAccuroButton;

PushAccuroButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
