import React, { useEffect, useState } from 'react';
import {
  Grid, Box, CircularProgress,
} from '@mui/material';

import SiteInfoTable from './SiteInfoTable';
import { axiosErrorToUserMessage } from '../services/axios';
import Toast, { useToast } from '../components/useToast';
import CustomAppBar from '../content/Appbar/CustomAppBar';
import { useApiClient } from '../context/ApiClientContext';
import basicServiceHandler from '../services/basicServiceHandler';
import CreateNewSiteButton from '../content/CreateNewSiteButton';
import SearchBar from '../components/SearchBar';
import useSiteSettings from '../hooks/useSiteSettings';
import usePermission from '../hooks/usePermissions';

export default function SiteFrontend() {
  const { accessibleSiteInfos } = useSiteSettings();

  const [siteData, setSiteData] = useState([]);
  const [searchSite, setSearchSite] = useState('');
  const [filteredSites, setFilteredSites] = useState(siteData);
  const [intakePlansLoading, setIntakePlansLoading] = useState({});
  const [loading, setLoading] = useState(true);

  const apiClient = useApiClient();
  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const { isAdmin } = usePermission();

  const fetchIntakePlans = (id) => basicServiceHandler(
    () => apiClient.getIntakePlansBySiteId(id),
    (result) => result.data,
    (error) => {
      const errorMessage = axiosErrorToUserMessage(error) || 'Error fetching intake plans.';
      toastSetErrorNotification(errorMessage);
      return [{ uniqueName: errorMessage }];
    },
  );

  const fetchIntakePlansForSite = async (siteId) => {
    try {
      const intakePlans = await fetchIntakePlans(siteId);
      setSiteData((prevData) => {
        const updatedData = prevData.map((site) => (
          site.id === siteId ? { ...site, intakePlans } : site));
        setFilteredSites(updatedData);
        return updatedData;
      });
    } catch (error) {
      const errorMessage = axiosErrorToUserMessage(error) || 'Error fetching intake plans.';
      toastSetErrorNotification(errorMessage);
      setSiteData((prevData) => {
        const updatedData = prevData.map((site) => (
          site.id === siteId ? { ...site, intakePlans: [] } : site));
        setFilteredSites(updatedData);
        return updatedData;
      });
    } finally {
      setIntakePlansLoading((prevState) => ({
        ...prevState,
        [siteId]: false,
      }));
    }
  };

  const fetchSiteData = () => {
    basicServiceHandler(
      () => apiClient.getAllSite(),
      async (result) => {
        const tempSiteData = result.data;
        setSiteData(tempSiteData);
        setFilteredSites(tempSiteData);
        const initialLoadingState = tempSiteData.reduce((acc, site) => {
          acc[site.id] = true;
          return acc;
        }, {});
        setIntakePlansLoading(initialLoadingState);
        tempSiteData.forEach((site) => fetchIntakePlansForSite(site.id));
        setLoading(false);
      },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error fetching sites.',
        );
        setLoading(false);
      },
    );
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchSite(inputValue);

    const filteredData = siteData
      .filter((site) => site.name.toLowerCase().includes(inputValue.toLowerCase()));
    setFilteredSites(filteredData);
  };
  useEffect(() => {
    if (accessibleSiteInfos.length > 0) {
      fetchSiteData();
    }
  }, [accessibleSiteInfos]);

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container>
        <Grid sx={{ width: 1 }}>
          <CustomAppBar
            siteSelectorOn={false}
            name="Site Settings Page"
          />
        </Grid>
        <Grid sx={{ width: 1, padding: '0 30px' }}>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem',
          }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column-reverse', sm: 'row' },
            }}
          >
            <SearchBar
              value={searchSite}
              onChange={handleSearchInputChange}
              placeholder="Search by Site Name"
            />
            <CreateNewSiteButton
              setErrorMessage={toastSetErrorNotification}
              setSuccessMessage={toastSetSuccessNotification}
              refreshParent={fetchSiteData}
            />
          </Box>
          {loading ? (
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
            }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <SiteInfoTable
              isAdmin={isAdmin()}
              data={filteredSites}
              intakePlansLoading={intakePlansLoading}
              setErrorMessage={toastSetErrorNotification}
              setSuccessMessage={toastSetSuccessNotification}
              refreshParent={fetchSiteData}
            />
          )}
          <Toast
            open={toastState.open}
            severity={toastState.severity}
            text={toastState.text}
            onClose={toastOnClose}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
