import React from 'react';
import {
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { Controls } from '../../../../components/controls/Controls';
import { useForm } from '../../../../components/useForm';

const initialFieldValues = {
  appointmentTypeId: undefined,
  appointmentTypeName: '',
};

export default function AddAppointmentTypeModal(props) {
  const {
    isOpen,
    handleClose,
    appointmentPlanPairs,
    setAppointmentPlanPairs,
  } = props;

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const validate = (fieldValue) => {
    const { appointmentTypeId, appointmentTypeName } = values;
    const errorMsg = {
      appointmentTypeId: fieldValue.appointmentTypeId ? '' : 'Please enter appointment Id',
      appointmentTypeName: fieldValue.appointmentTypeName ? '' : 'Please enter appointment name',
    };
    const typeName = appointmentPlanPairs.find(
      (item) => item.name === appointmentTypeName,
    );
    const typeId = appointmentPlanPairs.find((item) => item.id === Number(appointmentTypeId));
    errorMsg.appointmentTypeId = typeId ? 'Duplicate ID exists' : '';
    errorMsg.appointmentTypeName = typeName ? 'Duplicate Name exists' : '';
    if (Number.isNaN(Number(fieldValue.appointmentTypeId))) {
      errorMsg.appointmentTypeId = 'Please enter number';
    }
    setErrors({ ...errorMsg });

    return Object.values(errorMsg).every((item) => item === '');
  };

  const handleSaveNewType = () => {
    const valid = validate(values);
    if (valid) {
      const { appointmentTypeId, appointmentTypeName } = values;
      setAppointmentPlanPairs(
        [
          ...appointmentPlanPairs,
          { id: Number(appointmentTypeId), name: appointmentTypeName, intakeModuleId: null },
        ],
      );
      setValues(initialFieldValues);
      handleClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      size="sm"
      title="Enter Accuro appointment type"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        rowSpacing={1}
      >
        <Controls.Input
          name="appointmentTypeId"
          value={values.appointmentTypeId}
          onChange={handleInputChange}
          error={errors.appointmentTypeId}
          label="Please enter ID"
        />
        <Controls.Input
          name="appointmentTypeName"
          value={values.appointmentTypeName}
          onChange={handleInputChange}
          error={errors.appointmentTypeName}
          label="Please enter name"
        />
        <Controls.Button
          text="Save"
          onClick={handleSaveNewType}
        />
      </Grid>
    </Modal>
  );
}

AddAppointmentTypeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  appointmentPlanPairs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      intakeModuleId: PropTypes.number,
    }),
  ).isRequired,
  setAppointmentPlanPairs: PropTypes.func.isRequired,
};
