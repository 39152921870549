import React from 'react';
import PropTypes from 'prop-types';

import {
  Button as MuiButton, Box, Typography,
} from '@mui/material';

const convertTemplateToButton = (template, intakeContent, setIntakeContent) => {
  const htmlContent = template.content.replace('\n', '<br>');
  return (
    <Box key={template.name}>
      <MuiButton
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => setIntakeContent(`${intakeContent}${htmlContent}`)}
      >
        {template.name}
      </MuiButton>
      <br />
    </Box>
  );
};

export default function OfferedTemplates(props) {
  const {
    examTemplates, planTemplates, intakeContent, setIntakeContent,
  } = props;

  return (
    <Box display="flex" justifyContent="center">
      {examTemplates?.length > 0
        && (
        <Box padding="30px">
          <Typography>Exam Templates</Typography>
          {examTemplates.map((template) => (
            convertTemplateToButton(template, intakeContent, setIntakeContent)))}
        </Box>
        )}
      {planTemplates?.length > 0
        && (
        <Box padding="30px">
          <Typography>Assessment/Plan Templates</Typography>
          {planTemplates.map((template) => (
            convertTemplateToButton(template, intakeContent, setIntakeContent)))}
        </Box>
        )}
    </Box>
  );
}

const templatePropType = PropTypes.arrayOf(PropTypes.shape(
  { name: PropTypes.string.isRequired, content: PropTypes.string.isRequired },
));

OfferedTemplates.propTypes = {
  examTemplates: templatePropType.isRequired,
  planTemplates: templatePropType.isRequired,
  intakeContent: PropTypes.string.isRequired,
  setIntakeContent: PropTypes.func.isRequired,
};
