import { darken } from 'polished';
import useMediaQuery from '@mui/material/useMediaQuery';

const customizeTheme = () => {
  const isMobile = useMediaQuery('(max-width:425px)');

  const activeButtonColor = '#1F64AF';
  const hoverColorContained = darken(0.1, activeButtonColor);
  const primaryTheme = '#1F64AF';
  const fullRoundedBorderRadius = '999px';
  const modalBorderRadius = '16px';
  return {
    typography: {
      fontFamily: 'Poppins,Nunito,Roboto,Helvetica,Arial,sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.4',
      },
      h2: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.4',
      },
      body1: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5',
      },
      semibold: {
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.5',
      },
    },
    palette: {
      primary: {
        main: primaryTheme,
      },
      // Since the color below are very safe to match all the themes,
      // we can use them as hard-coded values
      border: {
        main: '#a1a1a1',
      },
      background: {
        main: '#d0d0d0',
        secondary: '#e6e6e605',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            width: isMobile ? '100%' : 'auto',
            '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
              backgroundColor: activeButtonColor,
              color: 'white',
              border: `1px solid ${activeButtonColor}`,
              padding: '6px 28px',
              borderRadius: fullRoundedBorderRadius,
              fontWeight: '600',
              '&:active': {
                backgroundColor: '#1F64B0 !important',
                color: 'white',
              },
              '&.Mui-selected': {
                backgroundColor: '#1F64B0 !important',
                color: 'white',
              },
              '&:hover': {
                backgroundColor: hoverColorContained,
                color: 'white',
              },
            },
            '&.MuiButton-outlinedPrimary:not(.Mui-disabled)': {
              textTransform: 'capitalize',
              color: primaryTheme,
              border: `1px solid ${primaryTheme}`,
              borderRadius: fullRoundedBorderRadius,
              padding: '6px 28px',
              minWidth: '36px',
              fontWeight: 600,
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: hoverColorContained,
                border: `1px solid ${hoverColorContained}`,
                color: '#fff',
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover fieldset': {
              borderColor: 'rgba(31, 100, 175, 0.54) !important',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(31, 100, 175, 0.54) !important',
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            borderRadius: fullRoundedBorderRadius,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: modalBorderRadius,
          },
        },
      },
    },
  };
};

export default customizeTheme;
