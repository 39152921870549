import PasswordValidator from 'password-validator';
import passwords from './blacklistedPasswords.json';

const schema = new PasswordValidator();
const blackListedPasswords = passwords.passwords;
schema.is().min(8);

schema.usingPlugin(
  (password) => !(/(.)\1\1/).test(password),
  'Should not have more than two repeating characters in a row',
);

const validatePassword = (password) => {
  const isValid = schema.validate(password);

  const errorList = isValid ? [] : schema.validate(password, { list: true });

  if (blackListedPasswords.includes(password)) {
    errorList.push('blacklisted');
  }

  const errorMessages = {
    min: 'Password is too short.',
    usingPlugin: 'Exceeding two repeated consecutive characters.',
    blacklisted: 'Password is too common.',
  };

  const errors = errorList.map((errorCode) => errorMessages[errorCode]);

  return {
    isValid: isValid && !errorList.includes('blacklisted'),
    errors,
  };
};

export default validatePassword;
