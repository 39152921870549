import React, { useEffect } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';

import useSiteSettings from '../hooks/useSiteSettings';

export default function SiteSelector() {
  const { activeSiteId, setActiveSiteId, accessibleSiteInfos } = useSiteSettings();

  const handleSelect = (values) => {
    setActiveSiteId(values.selectedSite);
    localStorage.setItem('activeSite', values.selectedSite);
  };
  const initialFieldValues = {
    selectedSite: activeSiteId || '',
  };
  const {
    values, handleInputChange, setValues,
  } = useForm(initialFieldValues, handleSelect);

  useEffect(() => {
    setValues({
      ...values,
      selectedSite: activeSiteId,
    });
  }, [activeSiteId]);
  return (
    <Grid container>
      <Grid item xs={6}>
        {accessibleSiteInfos.length > 0
          && (accessibleSiteInfos.length === 1 ? (
            <Typography
              variant="h6"
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'center',
                color: '#000',
              }}
            >
              {accessibleSiteInfos[0].name}
            </Typography>
          ) : (
            <Controls.Input
              label="Active Site"
              name="selectedSite"
              value={values.selectedSite}
              onChange={handleInputChange}
              select
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '32px',
                },
              }}
            >
              {accessibleSiteInfos.map((site) => (
                <MenuItem key={site.id} value={site.id}>
                  {site.name}
                </MenuItem>
              ))}
            </Controls.Input>
          ))}
      </Grid>
    </Grid>
  );
}
