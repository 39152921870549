export default class ApiClient {
  constructor(
    mcAxiosInstance,
    customerAxiosInstance,
    intakeAxiosInstance,
    integrationAxiosInstance,
  ) {
    this.mcAxiosInstance = mcAxiosInstance;
    this.customerAxiosInstance = customerAxiosInstance;
    this.intakeAxiosInstance = intakeAxiosInstance;
    this.integrationAxiosInstance = integrationAxiosInstance;
  }

  // Site
  getSite(siteId) {
    return this.customerAxiosInstance.get(`/${siteId}`, {
      params: {
        site_id: siteId,
      },
    });
  }

  getAllSite() {
    return this.customerAxiosInstance.get('/getAllSites');
  }

  getUsersBySiteId(siteId) {
    return this.customerAxiosInstance.get(`/allUsers/${siteId}`);
  }

  assignUsersToSiteBySiteId(siteId, usernames) {
    return this.customerAxiosInstance.put(`/${siteId}/assignUsers`, { usernames });
  }

  unassignUserFromSiteBySiteId(siteId, username) {
    return this.customerAxiosInstance.put(`/${siteId}/unassignUser`, { username });
  }

  updateSiteSetting(siteId, setting) {
    return this.customerAxiosInstance.put(
      `/updateSetting/${siteId}`,
      setting,
    );
  }

  deleteSite(siteId) {
    return this.customerAxiosInstance.delete(`/${siteId}`);
  }

  createSite(data) {
    return this.customerAxiosInstance.post('/', {
      name: data.name,
      customerId: data.customerId,
      settings: data.settings,
    });
  }

  deleteSiteSetting(siteId, settingName, settingCategory) {
    return this.customerAxiosInstance.delete(`/${siteId}/${settingCategory}/${settingName}`);
  }

  updateUser(username, userChanges) {
    return this.customerAxiosInstance.put(`/customer/user/${username}`, { ...userChanges });
  }

  updateMfaMethod(username, mfaMethod) {
    return this.customerAxiosInstance.put(`/customer/user/${username}/mfaMethod`, { mfaMethod });
  }

  // Customer
  getCustomer(customerId) {
    return this.customerAxiosInstance.get(`/customer/${customerId}`);
  }

  getAllCustomers() {
    return this.customerAxiosInstance.get('/customer/getAllCustomers');
  }

  getAssignedCustomers() {
    return this.customerAxiosInstance.get('/customer/assigned');
  }

  getUsersByCustomerId(customerId) {
    return this.customerAxiosInstance.get(`/customer/${customerId}/users`);
  }

  getAllUsers() {
    return this.customerAxiosInstance.get('/customer/*/users');
  }

  copyCustomerSettings(sourceCustomerId, targetCustomerId) {
    return this.customerAxiosInstance.post('/customer/copySettings', {
      sourceCustomerId,
      targetCustomerId,
    });
  }

  deleteCustomerSetting(customerId, settingName, settingCategory) {
    return this.customerAxiosInstance.delete(`/customer/${customerId}/${settingCategory}/${settingName}`);
  }

  updateCustomer(customerId, customerData) {
    return this.customerAxiosInstance.put(`/customer/${customerId}`, customerData);
  }

  createCustomer(customerData) {
    return this.customerAxiosInstance.post('/customer', customerData);
  }

  getPatientEmailTemplates() {
    return this.customerAxiosInstance.get('/customer/patientEmailTemplates');
  }

  sendPatientSupportEmail(data) {
    return this.customerAxiosInstance.post('/customer/sendPatientSupportEmail', data);
  }

  // Intake
  createIntake(data) {
    return this.intakeAxiosInstance.post('/create', data);
  }

  sendSMS(number, mkey) {
    return this.intakeAxiosInstance.post('/messages', { to: number, mkey });
  }

  getAllIntakeData(activeSiteId, page, limit) {
    return this.intakeAxiosInstance.get('/', {
      params: {
        site_Id: activeSiteId,
        page,
        limit,
      },
    });
  }

  checkIfPatientUniqueToSite(patientId, siteId) {
    return this.intakeAxiosInstance.get(`/pii/${siteId}/${patientId}`);
  }

  searchIntakesByPatientIdPrefix(siteId, patientIdPrefix) {
    return this.intakeAxiosInstance.get(`/search/${siteId}/${patientIdPrefix}`);
  }

  getIntakeReview(activeSiteId, mkey) {
    return this.intakeAxiosInstance.get(`/${activeSiteId}/${mkey}/review`);
  }

  getIntakeTrace(mkey) {
    return this.intakeAxiosInstance.get(`/${mkey}/trace`);
  }

  deleteIntake(mkey) {
    return this.intakeAxiosInstance.delete(`/${mkey}`);
  }

  deleteIntakeByHxId(siteId, hxId) {
    return this.intakeAxiosInstance.delete(`/intake/${siteId}/${hxId}`);
  }

  getPhoneNumberType(phoneNumber) {
    return this.intakeAxiosInstance.get(`/getPhoneNumberType/${phoneNumber}`);
  }

  resendSMS(mkey, siteId) {
    return this.intakeAxiosInstance.post(`/resendSMS/${siteId}/${mkey}`);
  }

  // Medical Content
  getIntakePlans() {
    return this.mcAxiosInstance.get('/intakePlan');
  }

  getIntakePlan(intakePlanId) {
    return this.mcAxiosInstance.get(`/intakePlan/${intakePlanId}`);
  }

  getModule(moduleId) {
    return this.mcAxiosInstance.get(`/module/${moduleId}`);
  }

  getModuleList() {
    return this.mcAxiosInstance.get('/module/intakePlan/search');
  }

  async getIntakePlansBySiteId(siteId) {
    try {
      // Fetch site settings
      const siteResponse = await this.getSite(siteId);
      if (!siteResponse?.data) {
        return { status: 200, data: [{ referenceCode: 'intakePlans setting not found.' }] };
      }

      // Check for intakePlans in site settings
      let intakePlans = siteResponse.data.settings.find((setting) => setting.name === 'intakePlans');

      // If intakePlans not found, check defaultSettings in siteResponse.data
      if (!intakePlans || intakePlans.value === '') {
        const { defaultSettings } = siteResponse.data;
        intakePlans = defaultSettings.find((setting) => setting.name === 'intakePlans');
      }

      // If still not found, return "intakePlans setting not found."
      if (!intakePlans || intakePlans.value === '') {
        return { status: 200, data: [{ referenceCode: 'intakePlans setting not found.' }] };
      }

      // Check if intakePlans value is '*'
      if (intakePlans.value === '*') {
        return { status: 200, data: [{ referenceCode: 'All intake plans selected.' }] };
      }

      // Get specific intake plans based on IDs
      const intakePlanIds = intakePlans.value.split(',');
      const results = await Promise.all(intakePlanIds.map((id) => this.getIntakePlan(id)));
      return { status: 200, data: results.map((result) => result.data) };
    } catch (error) {
      return error.response
        ? { status: error.response.status, data: error.response.data }
        : { status: 500, data: error };
    }
  }

  // Integration
  loginAccuro(siteId) {
    return this.integrationAxiosInstance.post(
      '/v1/private/accuro/login',
      {},
      {
        params: {
          siteId,
        },
      },
    );
  }

  refreshAccuroToken(siteId) {
    return this.integrationAxiosInstance.post('/v1/private/accuro/refreshAndSave', {
      siteId,
    });
  }

  searchAccuroPatient(patientHealthNumber, siteId) {
    return this.integrationAxiosInstance.post('/v1/private/accuro/search', {
      patientHealthNumber,
      siteId,
    });
  }

  handleAccuroPush(mkey, identifiableData, siteId) {
    return this.integrationAxiosInstance.post('/', {
      mkey,
      identifiableData,
      siteId,
    });
  }

  forceAccuroAutomation(siteId) {
    return this.integrationAxiosInstance.post('/v1/private/accuro/forceAccuroAutomation', {
      siteId,
    });
  }

  getSpeechToTextToken() {
    return this.integrationAxiosInstance.post('/v1/private/getSpeechToken');
  }

  uploadLogo(file) {
    return this.customerAxiosInstance.post('/upload/', file);
  }

  getIframePageUrl(params) {
    return this.integrationAxiosInstance.get('v1/private/getIframePageUrl', {
      params,
    });
  }

  loginPointClickCare(siteId) {
    return this.integrationAxiosInstance.post(
      '/v1/private/pointClickCare/login',
      {},
      {
        params: {
          siteId,
        },
      },
    );
  }

  searchPointClickCarePatient(patientName, siteId) {
    return this.integrationAxiosInstance.get(
      '/v1/private/pointClickCare/search',
      {
        params: {
          patientName,
          siteId,
        },
      },
    );
  }

  getPointClickCarePatientContacts(patientId, siteId) {
    return this.integrationAxiosInstance.get(
      '/v1/private/pointClickCare/contacts',
      {
        params: {
          patientId,
          siteId,
        },
      },
    );
  }

  translateReport(report) {
    return this.integrationAxiosInstance.post(
      '/v1/private/translate',
      {
        report,
      },
    );
  }
}
