import React, { useState, createContext, useMemo } from 'react';
import { Chip, Box, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { Controls } from './controls/Controls';

export const basicRole = [
  { name: 'Intake Creator', value: 'IntakeCreator' },
  { name: 'Intake Manager', value: 'IntakeManager' },
];

export const apiUserRole = { name: 'API User', value: 'ApiUser' };
export const accuroRole = { name: 'Intake Manager (Accuro)', value: 'IntakeManagerAccuro' };

export const adminRole = [
  { name: 'Site Admin', value: 'SiteAdmin' },
  { name: 'Customer Admin', value: 'CustomerAdmin' },
];
export const c2iAdmin = { name: 'C2i Admin', value: 'C2iAdmin' };

export const SuperAdmin = { name: 'FirstHx Admin', value: 'SuperAdmin' };

export const allRoles = [
  ...basicRole,
  accuroRole,
  ...adminRole,
  c2iAdmin,
  SuperAdmin,
];

export const RolesContext = createContext();

export function RolesProvider({ children }) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const value = useMemo(() => ({
    selectedRoles,
    setSelectedRoles,
  }), [selectedRoles, setSelectedRoles]);

  return (
    <RolesContext.Provider value={value}>
      {children}
    </RolesContext.Provider>
  );
}

export function getRoleObjects(userRoles) {
  return allRoles.filter((role) => userRoles.includes(role.value));
}

export function RolesDropdown({
  user,
  isEditing,
  isAdmin,
  name,
  isCustomerAdmin,
  hasAccuroAccess,
  handleInputChange,
  selectedRoles,
  error,
}) {
  const rolesToBeDisplayed = [];
  if (isAdmin) {
    rolesToBeDisplayed.push(...basicRole, ...adminRole, c2iAdmin, SuperAdmin, apiUserRole);
  } else if (isCustomerAdmin) {
    rolesToBeDisplayed.push(...adminRole, apiUserRole);
  } else {
    rolesToBeDisplayed.push(adminRole[0], apiUserRole);
  }

  if (isAdmin || hasAccuroAccess) {
    rolesToBeDisplayed.unshift(accuroRole);
  } else {
    rolesToBeDisplayed.unshift(...basicRole);
  }

  return isEditing ? (
    <Controls.Select
      multiple
      value={selectedRoles}
      error={error}
      name={name}
      label="Role"
      onChange={handleInputChange}
      data-testid="RoleDropDown"
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected && selected.map((val) => (
            <Chip key={val} label={rolesToBeDisplayed.find((role) => role.value === val).name} />
          ))}
        </Box>
      )}
    >
      {rolesToBeDisplayed
      && rolesToBeDisplayed.map((item) => (
        <MenuItem key={item.name} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Controls.Select>
  ) : (
    <div>
      {user && user.roles && getRoleObjects(user.roles).map((role) => (
        <div key={role.name}>
          {role.name}
        </div>
      ))}
    </div>
  );
}

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

RolesDropdown.defaultProps = {
  error: null,
  user: null,
};

RolesDropdown.propTypes = {
  user: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  isEditing: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCustomerAdmin: PropTypes.bool.isRequired,
  hasAccuroAccess: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  selectedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default RolesDropdown;
