import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Box,
} from '@mui/material';
import { Controls } from '../components/controls/Controls';

function ContactUs({ toggleView }) {
  return (
    <Box
      sx={{
        my: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '600px',
      }}
    >
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        <strong>Tech Support:</strong>
      </Typography>
      <Typography variant="body1">
        <a href="mailto:support@firsthx.com">support@firsthx.com</a>
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        <strong>Finance Department:</strong>
      </Typography>
      <Typography variant="body1">
        <a href="mailto:finance@firsthx.com">finance@firsthx.com</a>
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        <strong>Marketing:</strong>
      </Typography>
      <Typography variant="body1">
        <a href="mailto:marketing@firsthx.com">marketing@firsthx.com</a>
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1 }}>
        <strong>Sales/Other Inquiries:</strong>
      </Typography>
      <Typography variant="body1">
        <a href="mailto:info@firsthx.com">info@firsthx.com</a>
      </Typography>
      <Controls.Button
        type="button"
        fullWidth
        variant="text"
        sx={{ mt: 3, mb: 2, width: 1 }}
        text="Back to Login"
        onClick={toggleView}
      />
    </Box>
  );
}

ContactUs.propTypes = {
  toggleView: PropTypes.func.isRequired,
};

export default ContactUs;
