import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Button } from '@mui/material';
import PropTypes from 'prop-types';

function SiteTopNavigationBar({
  isAdmin, siteName, siteId,
}) {
  const buttonStyle = {
    fontSize: '1.1rem',
    padding: '10px 20px',
  };

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar variant="dense">
        {isAdmin && (
          <Button
            color="inherit"
            component={NavLink}
            to={`/site/${siteId}`}
            style={buttonStyle}
            data-testid="site-settings-button"
          >
            Settings
          </Button>
        )}
        <Button
          color="inherit"
          component={NavLink}
          to={`/site/${siteId}/${siteName}/users`}
          style={buttonStyle}
          data-testid="site-users-button"
        >
          Users
        </Button>
      </Toolbar>
    </AppBar>
  );
}

SiteTopNavigationBar.propTypes = {
  siteName: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default SiteTopNavigationBar;
