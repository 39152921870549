import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper, Box } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

// ZOOM_FACTOR represents how much the image should zoom in each time the zoom in button is clicked
const ZOOM_FACTOR = 1.2;
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2;

function ZoomableImage({ image, onZoomChange }) {
  const handleZoomIn = () => {
    // ensures that the new zoom level does not exceed MAX_ZOOM
    const newZoom = Math.min((image.zoom || 1) * ZOOM_FACTOR, MAX_ZOOM);
    onZoomChange(newZoom);
  };

  const handleZoomOut = () => {
    // ensures that the new zoom level does not go below MIN_ZOOM
    const newZoom = Math.max((image.zoom || 1) / ZOOM_FACTOR, MIN_ZOOM);
    onZoomChange(newZoom);
  };

  return (
    <Box sx={{ margin: '10px' }}>
      <img
        src={image.urlWithAccessToken}
        alt={`Attached ${image.index + 1}`}
        style={{
          maxWidth: `${400 * (image.zoom || 1)}px`,
          maxHeight: `${400 * (image.zoom || 1)}px`,
        }}
      />
      <Paper
        elevation={0}
        sx={{
          backgroundColor: 'rgba(240, 240, 240, 0.5)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={handleZoomIn} size="small"><ZoomInIcon /></IconButton>
        <IconButton onClick={handleZoomOut} size="small"><ZoomOutIcon /></IconButton>
      </Paper>
    </Box>
  );
}

ZoomableImage.propTypes = {
  image: PropTypes.shape({
    urlWithAccessToken: PropTypes.string.isRequired,
    zoom: PropTypes.number,
    index: PropTypes.number.isRequired,
  }).isRequired,
  onZoomChange: PropTypes.func.isRequired,
};

export default ZoomableImage;
