import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  InputLabel,
} from '@mui/material';
import Modal from '../../components/Modal';

import { Controls } from '../../components/controls/Controls';

export function ApiUserConfirmationModal({
  isOpen, handleClose, credential, setSuccessMessage,
}) {
  const handleCopy = () => {
    navigator.clipboard.writeText(credential.password); // Copy text to clipboard
    setSuccessMessage('Successfully copied password');
  };
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Successfully created API user"
    >
      <Box marginBottom={2}>
        <Typography variant="body1" sx={{ color: 'black' }}>
          Please ensure to securely save the following credentials before closing this window.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        marginBottom={2}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.1)',
          padding: '10px',
        }}
      >
        <InputLabel sx={{ color: 'black' }}>
          {'Username: '}
          {credential.username}
        </InputLabel>
        <InputLabel sx={{ color: 'black' }}>
          {'Password: '}
          {credential.password}
        </InputLabel>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Controls.Button
          onClick={handleCopy}
          text="Copy Password"
        />
      </Box>
    </Modal>
  );
}

export default ApiUserConfirmationModal;

ApiUserConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  credential: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};
