import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import UserInfoTable from './UserInfoTable';
import { useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import Toast, { useToast } from '../../components/useToast';
import CustomAppBar from '../../content/Appbar/CustomAppBar';
import SiteTopNavigationBar from '../SiteTopNavigationBar';
import usePermission from '../../hooks/usePermissions';
import { Controls } from '../../components/controls/Controls';
import AssignUsersToSiteModal from './AssignUsersToSiteModal';

function UserManagement() {
  const { siteId, siteName } = useParams();
  const [users, setUsers] = useState([]);
  const [isAssignUsersModalOpen, setIsAssignUsersModalOpen] = useState(false);
  const [shouldFetchUsers, setShouldFetchUsers] = useState(false);
  const apiClient = useApiClient();
  const { isAdmin } = usePermission();
  const isSuperAdmin = isAdmin();
  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const setSuccessMessage = (message) => {
    toastSetSuccessNotification(message);
  };

  const setErrorMessage = (message) => {
    toastSetErrorNotification(message);
  };

  const handleOpenAssignUsersModal = () => {
    setShouldFetchUsers(true);
    setIsAssignUsersModalOpen(true);
  };
  const handleCloseAssignUsersModal = () => {
    setShouldFetchUsers(false);
    setIsAssignUsersModalOpen(false);
  };

  const fetchUsers = () => {
    basicServiceHandler(
      async () => {
        const result = await apiClient.getUsersBySiteId(siteId);
        return result;
      },
      (result) => {
        let formattedUsers = result.data.map((user) => ({
          username: user.username,
          roles: user.roles,
          phoneNumber: user.phoneNumber,
          mfaMethod: user.mfaMethod,
        }));
        if (!isAdmin()) { // filter out FHX admin from the user list if user is not FHX admin
          formattedUsers = formattedUsers.filter((user) => !user.roles.includes('SuperAdmin'));
        }
        setUsers(formattedUsers);
      },
      () => {
        setUsers([]);
        setErrorMessage('Failed to fetch users');
      },
    );
  };

  useEffect(() => {
    fetchUsers();
  }, [siteId]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <CustomAppBar
          name={siteName}
        />
      </Grid>
      <Grid item xs={12}>
        <SiteTopNavigationBar
          isAdmin={isSuperAdmin}
          siteId={siteId}
          siteName={siteName}
          fetchUsers={fetchUsers}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
          marginRight: '20px',
        }}
      >
        <Controls.Button
          variant="outlined"
          data-testid="assign-users-button"
          text="Assign User"
          onClick={handleOpenAssignUsersModal}
        />
      </Grid>
      <Grid item xs={12} sx={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
        <UserInfoTable
          data={users}
          siteId={siteId}
          siteName={siteName}
          fetchUsers={fetchUsers}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </Grid>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
      <AssignUsersToSiteModal
        isOpen={isAssignUsersModalOpen}
        isAdmin={isSuperAdmin}
        assignedUsers={users}
        onClose={handleCloseAssignUsersModal}
        siteId={siteId}
        shouldFetchUsers={shouldFetchUsers}
        fetchUsers={fetchUsers}
      />
    </Grid>
  );
}

export default UserManagement;
