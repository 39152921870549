import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemText, ListItemIcon, Typography, Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryButton from '../components/CategoryButton';
import { Controls } from '../components/controls/Controls';
import CustomerCopySettingsModal from '../customers/CustomerCopySettingsModal';
import usePermission from '../hooks/usePermissions';

const getNavigationItems = (customer, site) => {
  if (customer && customer.sites) {
    return customer.sites.map((siteToDisplay) => ({
      id: siteToDisplay.id,
      name: siteToDisplay.name,
      link: `/site/${siteToDisplay.id}`,
      tooltip: `Go to site settings: ${siteToDisplay.name}`,
    }));
  } if (site) {
    return [{
      id: site.customerId,
      name: 'Customer Default Settings',
      link: `/customer/${site.customerId}`,
      tooltip: 'Go to customer settings',
    }];
  }
  return [];
};

function Sidebar(props) {
  const {
    onItemSelect,
    categories,
    selectedItem,
    handleSubCategorySelect,
    customer,
    site,
    refreshParent,
  } = props;

  const [isCopySettingModalOpen, setIsCopySettingModalOpen] = useState(false);
  const { isAdmin } = usePermission();

  const closeCopySettingModal = () => {
    setIsCopySettingModalOpen(false);
  };

  const handleItemClick = (item) => {
    if (onItemSelect) {
      onItemSelect(item);
    }
  };

  const handleSubCategoryClick = (subCategory) => {
    if (handleSubCategorySelect) {
      handleSubCategorySelect(subCategory);
    }
  };

  const navigationItems = getNavigationItems(customer, site);

  return (
    <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {categories.map((category) => (
        <CategoryButton
          key={category.id}
          category={category}
          handleItemClick={handleItemClick}
          handleSubCategoryClick={handleSubCategoryClick}
          highlight={category.id === selectedItem?.id}
        />
      ))}
      {isAdmin() && (
        <Controls.Button
          key="New Setting"
          variant="contained"
          sx={{ margin: '6px', marginBottom: '30px' }}
          text="New Setting"
          size="medium"
          onClick={() => handleItemClick({ name: 'New Setting' })}
        />
      )}
      {customer && isAdmin() && (
        <Controls.Button
          key="Copy settings from another customer"
          variant="contained"
          sx={{ margin: '6px', marginBottom: '30px' }}
          text="Copy settings from another customer"
          size="medium"
          onClick={() => {
            setIsCopySettingModalOpen(true);
          }}
        />
      )}
      <CustomerCopySettingsModal
        isOpen={isCopySettingModalOpen}
        handleClose={closeCopySettingModal}
        refreshParent={refreshParent}
      />
      {navigationItems.length > 0 && (
      <div data-testid="navigation-container">
        <Typography variant="subtitle1">
          Navigate to:
        </Typography>
        {navigationItems.map((item) => (
          <Tooltip title={item.tooltip} key={item.id}>
            <ListItem component={Link} to={item.link}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Tooltip>
        ))}
      </div>
      )}
    </List>
  );
}

Sidebar.propTypes = {
  onItemSelect: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  handleSubCategorySelect: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sites: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
  }),
  site: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
  }),
  refreshParent: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  selectedItem: null,
  customer: null,
  site: null,
};

export default Sidebar;
