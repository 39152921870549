import React from 'react';
import {
  Avatar,
  Paper,
  Box,
  Grid,
  Typography,
  CssBaseline,
} from '@mui/material';
import PropTypes from 'prop-types';

function FormContainer({ children, title }) {
  return (
    <div>
      <CssBaseline />
      <Grid
        container
        component="main"
        sx={{
          backgroundImage: 'url(/assets/images/hxmanager_login.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
        }}
      >
        <Grid
          item
          component={Paper}
          elevation={6}
          sx={{
            width: '100%',
            maxWidth: '500px',
            height: 'fitContent',
            padding: '20px',
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                width: '84px',
                height: '84px',
                backgroundColor: 'transparent',
              }}
            >
              <img
                alt="FirstHx Logo"
                src="/assets/images/FirstHx_symbol_colour.jpg"
                width="100%"
              />
            </Avatar>

            <Typography component="h1" variant="h5">
              {title}
            </Typography>

            {children}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default FormContainer;
