import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import Toast, { useToast } from '../components/useToast';
import CustomAppBar from '../content/Appbar/CustomAppBar';
import { useApiClient } from '../context/ApiClientContext';
import Sidebar from '../content/SideBar';
import NewSettingForm from '../content/NewSettingForm';
import { useForm } from '../components/useForm';
import Modal from '../components/Modal';
import CustomerSettingPane from './CustomerSettingsPane';
import basicServiceHandler from '../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../services/axios';
import useSiteSettings from '../hooks/useSiteSettings';
import settingCategories from '../utils/settingCategoriesData';

const initialFieldValues = {
  name: '',
  category: '',
  value: '',
};

export default function CustomerSettingsFrontend() {
  const { customerId } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newSetting, setNewSetting] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const { accessibleSiteInfos } = useSiteSettings();
  const {
    values, setValues, handleInputChange,
  } = useForm(initialFieldValues);
  const apiClient = useApiClient();
  const {
    toastSetErrorNotification,
    toastSetSuccessNotification,
    toastOnClose,
    toastState,
  } = useToast();

  const fetchCustomerSettings = () => {
    if (!customerId) return;
    basicServiceHandler(
      () => apiClient.getCustomer(customerId),
      (result) => {
        setCustomerData(result.data);
      },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'Error fetching customer info.',
        );
      },
    );
  };

  const updateCustomerSettings = async (updatedSetting) => {
    try {
      await apiClient.updateCustomer(customerId, updatedSetting);
      toastSetSuccessNotification('Settings updated successfully');
      fetchCustomerSettings();
    } catch (error) {
      toastSetErrorNotification('Error updating settings.');
    }
  };

  const handleCategorySelect = (item) => {
    setSelectedSubCategory(null);
    if (item.name === 'New Setting') {
      setNewSetting(true);
      return;
    }
    setSelectedCategory(item);
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const handleModalSubmit = () => {
    const updatedSetting = { ...values };
    updateCustomerSettings(updatedSetting);
    setNewSetting(false);
    setValues(initialFieldValues);
  };

  useEffect(() => {
    if (customerId && accessibleSiteInfos.length > 0) fetchCustomerSettings();
  }, [customerId, accessibleSiteInfos]);

  return (
    <Box sx={{ height: '100vh' }}>
      <CustomAppBar
        name={customerData && customerData.name}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Sidebar
            onItemSelect={handleCategorySelect}
            categories={settingCategories}
            selectedItem={selectedCategory}
            handleSubCategorySelect={handleSubCategorySelect}
            customer={customerData}
            refreshParent={fetchCustomerSettings}
          />
        </Grid>
        <Grid item xs={9}>
          {selectedCategory && (
          <CustomerSettingPane
            onSettingSave={updateCustomerSettings}
            category={selectedCategory}
            subCategory={selectedSubCategory}
            customer={customerData}
            refreshParent={fetchCustomerSettings}
            setErrorMessage={toastSetErrorNotification}
            setSuccessMessage={toastSetSuccessNotification}
          />
          )}
          <Modal
            isOpen={newSetting}
            handleClose={() => setNewSetting(false)}
          >
            <NewSettingForm
              values={values}
              setValues={setValues}
              handleInputChange={handleInputChange}
              onSubmit={handleModalSubmit}
              categories={settingCategories}
              contextType="customer"
            />
          </Modal>
        </Grid>
      </Grid>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </Box>
  );
}
