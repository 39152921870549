import React, {
  createContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext({});

// This component is responsible for providing the authentication state to the rest of the app.
export function AuthProvider({ children }) {
  // Initialize the authentication and persist state from local storage or as an empty object.
  const [auth, setAuth] = useState({});
  const [pointClickCareAuth, setPointClickCareAuth] = useState({});
  const accessibleSiteIdsLocalStorage = localStorage.getItem('accessibleSiteIds') || '[]';
  let accessibleSiteIdsDecoded = [];
  try {
    accessibleSiteIdsDecoded = JSON.parse(accessibleSiteIdsLocalStorage);
  } catch {
    accessibleSiteIdsDecoded = [];
  }

  const [accessibleSiteIds, setAccessibleSiteIds] = useState(accessibleSiteIdsDecoded);
  const [persist, setPersist] = useState(true);

  // Decode the access token and get the user's permissions, if available.
  const userPermissions = useMemo(() => {
    if (auth?.accessToken) {
      const decoded = jwtDecode(auth.accessToken);
      return decoded?.permissions || {};
    }
    return {};
  }, [auth]);

  // Use memoization to create a memoized object of the authentication state and set functions.
  const authState = useMemo(() => ({
    auth,
    setAuth,
    persist,
    setPersist,
    accessibleSiteIds,
    setAccessibleSiteIds,
    userPermissions,
    pointClickCareAuth,
    setPointClickCareAuth,
  }), [
    auth,
    setAuth,
    persist,
    setPersist,
    accessibleSiteIds,
    setAccessibleSiteIds,
    userPermissions,
    pointClickCareAuth,
    setPointClickCareAuth,
  ]);
  // Return the AuthContext provider with the authState as the value and the child components.
  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
