/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import {
  Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel, TableContainer, Paper,
} from '@mui/material';

export default function useTable(recordsToDisplay, headCells, filterFn) {
  const pages = [5, 10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[3]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  function TblContainer(props) {
    return (
      <TableContainer
        sx={{
          boxShadow: 'none',
          border: '1px solid #e0e0e0',
          borderRadius: '16px',
          mt: 2,
        }}
        component={Paper}
      >
        <Table>
          {props.children}
        </Table>
      </TableContainer>
    );
  }

  function TblHead() {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(cellId);
    };
    return (
      <TableHead
        sx={{
          backgroundColor: '#f2f2f26b',
        }}
      >
        <TableRow>
          {
            headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  color: '#6C6A6A',
                  fontSize: '0.9rem',
                }}
              >
                {headCell.disableSorting ? headCell.label : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => { handleSortRequest(headCell.id); }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TblPagination(props) {
    return (
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={props.count ?? recordsToDisplay.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) return newOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, newOrderBy) {
    if (b[newOrderBy] < a[newOrderBy]) {
      return -1;
    }
    if (b[newOrderBy] > a[newOrderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(newOrder, newOrderBy) {
    return newOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, newOrderBy)
      : (a, b) => -descendingComparator(a, b, newOrderBy);
  }

  const allPageDataSorted = () => stableSort(
    filterFn.fn(recordsToDisplay),
    getComparator(order, orderBy),
  );

  const currentPageDataSorted = () => {
    // First, slice the data to get only the records for the current page.
    const pageData = filterFn.fn(recordsToDisplay)
      .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    // Then, sort only the sliced data. This reduces the sorting overhead.
    return stableSort(
      pageData,
      getComparator(order, orderBy),
    );
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    page,
    setPage,
    rowsPerPage,
    currentPageDataSorted,
    allPageDataSorted,
  };
}
