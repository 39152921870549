import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import useTable from '../../../components/useTable';
import SendIntakeButton from '../../SendIntakeButton';

const header = [
  { id: 'patientId', label: 'Patient ID' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'healthCardNumber', label: 'Health Card Number' },
  { id: 'birthDate', label: 'Birth Date' },
  { id: 'gender', label: 'Gender' },
  { id: 'phoneNumber', label: 'Phone Number' },
];

export default function AccuroPatientTable(props) {
  const {
    patients, setSuccessMessage, setErrorMessage,
  } = props;

  const visibleHeader = useMemo(() => header, []);

  const {
    TblContainer,
    TblHead,
    currentPageDataSorted,
  } = useTable(patients, visibleHeader, { fn: (items) => items });

  useEffect(() => {
  }, [patients]);

  return (
    <TblContainer>
      <TblHead />
      <TableBody>
        {patients && currentPageDataSorted().map((patient) => (
          <TableRow
            key={patient.patientId}
          >
            <TableCell>{patient.patientId}</TableCell>
            <TableCell>{patient.demographics.firstName}</TableCell>
            <TableCell>{patient.demographics.lastName}</TableCell>
            <TableCell>{patient.demographics.healthCard.phn}</TableCell>
            <TableCell>{patient.demographics.birthday}</TableCell>
            <TableCell>{patient.demographics.genderId === 1 ? 'Male' : 'Female'}</TableCell>
            {patient.demographics.phones && patient.demographics.phones.map((item) => (
              <TableCell key={item.number}>
                <SendIntakeButton
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                  title={`${item.contactType}: ${item.number}`}
                  value={item.number}
                  patient={patient}
                  integrationType="accuro"
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TblContainer>
  );
}

AccuroPatientTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
