import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BottomNavigation,
  Container,
} from '@mui/material';
import {
  axiosErrorToUserMessage,
} from '../../services/axios';
import { useApiClient } from '../../context/ApiClientContext';
import Toast, { useToast } from '../../components/useToast';
import PatientSupportEmailButton from '../PatientSupportEmailButton';

export default function BottomNavBar({
  patientSupportEmailRecipient,
  activeSiteId,
}) {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();
  const apiClient = useApiClient();
  const fetchEmailTemplates = async () => {
    try {
      const emailTemplatesResponse = await apiClient.getPatientEmailTemplates();
      setEmailTemplates(emailTemplatesResponse.data);
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error)
        || 'Failed to fetch patient support email templates.',
      );
    }
  };

  useEffect(() => {
    fetchEmailTemplates();
  }, []);

  return (
    <Container
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        maxWidth: '100% !important', // To match Top navbar width
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.16)',
        zIndex: 1,
      }}
    >
      {emailTemplates && emailTemplates.length > 0 ? (
        <BottomNavigation
          showLabels
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            py: 2,
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 1,
            height: 'fit-content',
          }}
        >
          {emailTemplates.map((template, index) => (
            <PatientSupportEmailButton
              key={template.templateId}
              template={template}
              index={index}
              patientSupportEmailRecipient={patientSupportEmailRecipient}
              activeSiteId={activeSiteId}
              onError={toastSetErrorNotification}
              onSuccess={toastSetSuccessNotification}
              axiosError={axiosErrorToUserMessage}
            />
          ))}
        </BottomNavigation>
      ) : null}
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </Container>
  );
}

BottomNavBar.propTypes = {
  activeSiteId: PropTypes.string.isRequired,
  patientSupportEmailRecipient: PropTypes.string.isRequired,
};
