import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { htmlToText } from 'html-to-text';
import { IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import TranslateIcon from '@mui/icons-material/Translate';
import Tooltip from '@mui/material/Tooltip';
import { useApiClient } from '../../context/ApiClientContext';

export default function TranslateTextButton({ report, setEditorValue }) {
  const [translated, setTranslated] = useState(false);
  const [originalText, setOriginalText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const apiClient = useApiClient();

  const options = {
    wordwrap: null,
    selectors: [
      {
        selector: 'p',
        options: {
          leadingLineBreaks: 1,
          trailingLineBreaks: 1,
        },
      },
      {
        selector: 'br',
        format: 'skip',
      },
    ],
  };

  function textToHtml(text) {
    return `<p>${text.replace(/\n\n/g, '</p><p>&nbsp;</p><p>').replace(/\n/g, '</p><p>')}</p>`;
  }

  const handleTranslate = async () => {
    setIsDisabled(true);
    setOriginalText(report);
    const plainReport = htmlToText(report, options);
    const response = await apiClient.translateReport(plainReport);
    const { translatedReport } = response.data;
    const translatedReportInHtml = textToHtml(translatedReport);
    setEditorValue(translatedReportInHtml);
    setIsDisabled(false);
    setTranslated(true);
  };

  const displayOriginal = () => {
    setIsDisabled(true);
    setEditorValue(originalText);
    setIsDisabled(false);
    setTranslated(false);
  };

  return (
    translated ? (
      <Tooltip title="Display Original Report" arrow>
        <IconButton
          onClick={() => displayOriginal()}
          data-testid="displayOriginalIcon"
          disabled={isDisabled} // Disable the button
        >
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Translate Report" arrow>
        <IconButton
          onClick={() => handleTranslate()}
          data-testid="translateIcon"
          disabled={isDisabled} // Disable the button
        >
          <TranslateIcon />
        </IconButton>
      </Tooltip>
    )
  );
}

TranslateTextButton.propTypes = {
  report: PropTypes.string.isRequired,
  setEditorValue: PropTypes.func.isRequired,
};
