import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import {
  Box, Button,
  Divider, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  login,
  mfaVerify,
} from '../services/auth/authServices';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import Toast, { useToast } from '../components/useToast';
import useAuth from '../hooks/useAuth';
import useSiteSettings from '../hooks/useSiteSettings';
import { axiosErrorToUserMessage, axiosIntegrationInstance } from '../services/axios';
import PasswordRecovery from './PasswordRecovery';
import FormContainer from '../components/FormContainer';
import ContactUs from './ContactUs';
import basicServiceHandler from '../services/basicServiceHandler';
import { convertAxiosErrorToUserFacingMessage } from '../context/ApiClientContext';

const initialFieldValues = {
  username: '',
  password: '',
  mfaCode: '',
};

export default function FhxLogin() {
  const {
    setAuth,
  } = useAuth();
  const { updateSiteSettings } = useSiteSettings();
  const [signInLoading, setSignInLoading] = useState(false);
  const navigate = useNavigate();
  const [mfaStep, setMfaStep] = useState('preMfaLogin');
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState('');
  const [mfaEmail, setMfaEmail] = useState('');
  const [loginPageView, setLoginPageView] = useState('login');
  const toggleView = (view) => {
    setLoginPageView(view);
  };
  const {
    toastState,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const navigateToMfaSignup = (username, signUpToken) => {
    navigate('/multi-factor-signup', {
      state: { username, signUpToken },
    });
  };

  const updateAuthAndNavigate = (response) => {
    const { accessToken } = response.data;
    const decoded = jwtDecode(accessToken);
    setAuth({ accessToken });
    updateSiteSettings(decoded.siteIds);
    navigate('/home');
  };

  const handleErrors = (error) => {
    let errorMsg = {};
    const hasResponse = Boolean(error.response);
    const isInvalidVerificationCode = hasResponse
    && (error.response.data.message === 'Invalid verification code' || error.response.data.message === 'Unexpected MFA error type: InternalError');
    const isUnauthorizedStatus = hasResponse && (error.response.status === 403
      || error.response.status === 401);
    if (isInvalidVerificationCode) {
      toastSetErrorNotification('Invalid verification code');
    } else if (isUnauthorizedStatus) {
      errorMsg = mfaStep === 'preMfaLogin'
        ? { username: 'Invalid username/password', password: 'Invalid username/password' }
        : { mfaCode: 'Invalid MFA code' };
    } else if (hasResponse) {
      errorMsg = axiosErrorToUserMessage(error) || 'Authentication error';
    } else {
      toastSetErrorNotification(error.message);
    }
    setErrors({ ...errorMsg });
  };

  const handleLogin = async () => {
    try {
      const response = await login(values.username, values.password);
      if (response.status === 202) {
        if (response.data.info.status === 'MfaPending') {
          values.token = response.data.info.token;
          if (response.data.info.mfaMethod === 'SMS') {
            setMfaPhoneNumber(response.data.info.phoneNumber);
          } else if (response.data.info.mfaMethod === 'EMAIL') {
            setMfaEmail(response.data.info.email);
          }
          setMfaStep('verifyCode');
        } else if (response.data.info.status === 'MfaNotSet') {
          navigateToMfaSignup(values.username, response.data.info.token);
        }
      }
      if (response.status === 200) {
        // Successful login if mfa not required
        updateAuthAndNavigate(response);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleMfaVerify = async () => {
    try {
      const response = await mfaVerify(values.username, values.mfaCode, values.token);
      if (response.status === 200) {
        updateAuthAndNavigate(response);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSignInLoading(true);

    if (mfaStep === 'preMfaLogin') {
      await handleLogin();
    } else {
      await handleMfaVerify();
    }
    setSignInLoading(false);
  };

  const handlePointClickCareLogin = async () => {
    basicServiceHandler(
      () => axiosIntegrationInstance.post(
        '/pointClickCare/login',
      ),
      (response) => { window.location.href = response.data; },
      (error) => toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error)),
    );
  };

  switch (loginPageView) {
    case 'recovery':
      return (
        <FormContainer title="Password Recovery">
          <PasswordRecovery toggleView={() => setLoginPageView('login')} />
        </FormContainer>
      );
    case 'contact':
      return (
        <FormContainer title="Contact Us">
          <ContactUs toggleView={() => setLoginPageView('login')} />
        </FormContainer>
      );
    case 'login':
      return (
        <FormContainer title="Sign in">
          <Box component="form" noValidate={false} onSubmit={handleSubmit} sx={{ mt: 1 }}>
            {mfaStep === 'preMfaLogin' && (
              <>
                <Controls.Input
                  fullWidth
                  label="Username"
                  name="username"
                  value={values.username}
                  error={errors.username}
                  onChange={handleInputChange}
                  sx={{ margin: '10px 0px' }}
                  data-testid="username"
                />
                <Controls.Input
                  fullWidth
                  label="Password"
                  name="password"
                  value={values.password}
                  error={errors.password}
                  type="password"
                  onChange={handleInputChange}
                  sx={{ margin: '10px 0px' }}
                  data-testid="password"
                />
              </>
            )}
            {mfaStep === 'verifyCode' && (
              <>
                <Box sx={{ mb: 2 }}>
                  {mfaEmail ? (
                    <span>
                      MFA verification. Please enter the code sent to your email:
                      {' '}
                      <strong>{mfaEmail}</strong>
                    </span>
                  ) : (
                    `MFA verification. Please enter the code sent to your phone number: ${mfaPhoneNumber}`
                  )}
                </Box>
                <Controls.Input
                  fullWidth
                  label="Verification Code"
                  name="mfaCode"
                  value={values.mfaCode}
                  error={errors.mfaCode}
                  onChange={handleInputChange}
                  sx={{ margin: '10px 0px' }}
                />
              </>
            )}
            <Controls.Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: 1 }}
              text={mfaStep === 'preMfaLogin' ? 'Sign In' : 'Verify'}
              loading={signInLoading}
              data-testid="signInButton"
            />
            <Divider sx={{ color: 'gray' }}>
              <Typography variant="body" sx={{ color: 'gray' }}>
                OR
              </Typography>
            </Divider>
            <Button
              sx={{
                mt: 2,
                mb: 1,
                width: 1,
                padding: 0,
              }}
              onClick={handlePointClickCareLogin}
            >
              <img
                alt="PointClickCare Logo"
                src="/assets/images/PointClickCare_sign_in.png"
                style={{
                  width: '75%',
                  height: 'auto',
                  maxHeight: '100%',
                }}
              />
            </Button>
            <Controls.Button
              type="button"
              fullWidth
              variant="text"
              sx={{ mt: 3, mb: 2, width: 1 }}
              text="Forgot Password?"
              onClick={() => toggleView('recovery')}
            />
            <Controls.Button
              type="button"
              fullWidth
              variant="text"
              sx={{ mb: 2, width: 1 }}
              text="Contact Us"
              onClick={() => toggleView('contact')}
            />
          </Box>
          <Toast
            open={toastState.open}
            severity={toastState.severity}
            text={toastState.text}
            onClose={toastOnClose}
          />
        </FormContainer>
      );
    default:
      return null;
  }
}
