import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import useTable from '../../../components/useTable';
import SendIntakeButton from '../../SendIntakeButton';

const header = [
  { id: 'medicalRecordNumber', label: 'MRN' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'birthDate', label: 'Birth Date' },
  { id: 'gender', label: 'Gender' },
  { id: 'sendIntake', label: 'Send Intake' },
];

export default function PointClickCareTable(props) {
  const {
    patients,
    setSuccessMessage,
    setErrorMessage,
  } = props;
  // const [modalVisible, setModalVisible] = useState(false);
  const visibleHeader = useMemo(() => header, []);

  const {
    TblContainer,
    TblHead,
    currentPageDataSorted,
  } = useTable(patients, visibleHeader, { fn: (items) => items });

  useEffect(() => {
  }, [patients]);

  return (
    <TblContainer>
      <TblHead />
      <TableBody>
        {patients && currentPageDataSorted().map((patient) => (
          <TableRow
            key={patient.patientId}
          >
            <TableCell>{patient.medicalRecordNumber}</TableCell>
            <TableCell>{patient.firstName}</TableCell>
            <TableCell>{patient.lastName}</TableCell>
            <TableCell>{patient.birthDate}</TableCell>
            <TableCell>{patient.gender}</TableCell>
            <TableCell>
              <SendIntakeButton
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                title="send intake"
                integrationType="pointClickCare"
                patient={patient}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TblContainer>
  );
}

PointClickCareTable.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.shape({
    patientId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    medicalRecordNumber: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
  })).isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
