import { useEffect } from 'react';
import useAuth from './useAuth';
import { usePointClickCareRefreshTokens, useRefreshTokens } from '../services/auth/authServices';

const publicUrl = ['/user/refresh', '/pointClickCare/login', '/pointClickCare/refresh', '/pointClickCare/revoke'];
// This custom hook is responsible for adding an authorization header to Axios requests
// using the user's access token. It also refreshes the access token if it has expired
// and the user has a refresh token.
const useAxiosPrivate = (axiosInstance) => {
  const refresh = useRefreshTokens();
  const refreshPointClickCare = usePointClickCareRefreshTokens();
  const {
    auth, setAuth, pointClickCareAuth, setPointClickCareAuth,
  } = useAuth();

  useEffect(() => {
    const getRefreshedConfig = async (config) => {
      const newTokens = await refresh();
      if (newTokens) {
        setAuth((prev) => ({
          ...prev,
          accessToken: newTokens.accessToken,
        })); // Update auth state with new token
        const updatedConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${newTokens.accessToken}`,
          },
        };
        return updatedConfig;
      }
      return config;
    };

    const setOrRefreshPointClickCareToken = async (config) => {
      // pointClickCare access token exist and not expired
      const { accessToken, accessTokenExpiryTime } = pointClickCareAuth;
      const isTokenExpired = !accessToken || new Date() > new Date(accessTokenExpiryTime);
      let pointClickCareToken = accessToken;
      if (isTokenExpired) {
        const newTokens = await refreshPointClickCare();
        if (newTokens) {
          setPointClickCareAuth(newTokens);
        }
        pointClickCareToken = newTokens.accessToken;
      }
      const updatedConfig = {
        ...config,
        headers: {
          ...config.headers,
          pointClickCareToken,
        },
      };
      return updatedConfig;
    };

    // Add a request interceptor to set the authorization header on outgoing requests.
    const requestIntercept = axiosInstance.interceptors.request.use(
      async (config) => {
        if (publicUrl.includes(config.url)) return config; // Skip refresh, revoke token request
        const newConfig = { ...config };
        if (!newConfig.headers.Authorization) {
          if (auth?.accessToken) {
            newConfig.headers.Authorization = `Bearer ${auth.accessToken}`;
          } else {
            return getRefreshedConfig(newConfig); // Refresh token if accessToken is undefined
          }
        }
        if (config.url.includes('/pointClickCare')) { // for pointClickCare request
          return setOrRefreshPointClickCareToken(newConfig);
        }
        return newConfig;
      },
      (error) => Promise.reject(error),
    );

    // Add a response interceptor to handle 403 (Forbidden) errors and refresh the access token.
    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newTokens = await refresh();
          if (!newTokens) return Promise.reject(error);
          prevRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
          return axiosInstance(prevRequest);
        }
        return Promise.reject(error);
      },
    );

    // Clean up the request and response interceptors when the component unmounts.
    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh, pointClickCareAuth?.accessToken, pointClickCareAuth?.accessTokenExpiryTime]);

  // Return the axios instance with the added interceptors and authorization header.
  return axiosInstance;
};

export default useAxiosPrivate;
