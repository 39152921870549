import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import ApiClient from '../services/ApiClient';
import {
  axiosCustomerInstance,
  axiosIntakeInstance,
  axiosIntegrationInstance,
  axiosMedicalContentInstance,
} from '../services/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export const convertAxiosErrorToUserFacingMessage = (axios500) => (
  axios500?.response?.data?.message || axios500.message || 'Unrecognized failure.');
const ApiClientContext = createContext();

export function ApiClientProvider({ children }) {
  const mcAxiosWithAuth = useAxiosPrivate(axiosMedicalContentInstance);
  const siteAxiosWithAuth = useAxiosPrivate(axiosCustomerInstance);
  const intakeAxiosWithAuth = useAxiosPrivate(axiosIntakeInstance);
  const integrationAxiosWithAuth = useAxiosPrivate(axiosIntegrationInstance);

  const apiClient = new ApiClient(
    mcAxiosWithAuth,
    siteAxiosWithAuth,
    intakeAxiosWithAuth,
    integrationAxiosWithAuth,
  );
  const memoizedApiClient = useMemo(() => apiClient, []);

  return (
    <ApiClientContext.Provider value={memoizedApiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}

export function useApiClient() {
  return useContext(ApiClientContext);
}

ApiClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiClientContext;
