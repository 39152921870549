import React from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../controls/Controls';

export default function AutoCompleteDropDownSetting(props) {
  const {
    setting,
    options,
    values,
    handleOptionChange,
  } = props;

  return (
    <Controls.AutocompleteInput
      options={options}
      label={setting.name}
      value={values}
      handleOptionChange={handleOptionChange}
      fullWidth
    />
  );
}

AutoCompleteDropDownSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  values: PropTypes.string,
  handleOptionChange: PropTypes.func.isRequired,
};

AutoCompleteDropDownSetting.defaultProps = {
  values: '',
};
