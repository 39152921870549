import axios from 'axios';

export const axiosErrorToUserMessage = (axiosError) => (
  axiosError?.response?.data?.message || axiosError?.message
);

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_ENDPOINT;
const INTAKE_BASE_URL = process.env.REACT_APP_INTAKE_ENDPOINT;
const CUSTOMER_BASE_URL = process.env.REACT_APP_CUSTOMER_ENDPOINT;
const MEDICAL_CONTENT_BASE_URL = process.env.REACT_APP_MEDICAL_CONTENT_ENDPOINT;
const INTEGRATION_BASE_URL = process.env.REACT_APP_INTEGRATION_ENDPOINT;

export const axiosIntakeInstance = axios.create({
  baseURL: `${INTAKE_BASE_URL}/v1/private`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosMedicalContentInstance = axios.create({
  baseURL: MEDICAL_CONTENT_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosAuthInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosCustomerInstance = axios.create({
  baseURL: CUSTOMER_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosIntegrationInstance = axios.create({
  baseURL: INTEGRATION_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
