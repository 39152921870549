import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { axiosIntegrationInstance } from '../services/axios';
import { useToast } from '../components/useToast';
import { convertAxiosErrorToUserFacingMessage } from '../context/ApiClientContext';
import useSiteSettings from '../hooks/useSiteSettings';

export default function FhxSsoLogin() {
  const {
    setAuth,
    setPointClickCareAuth,
  } = useAuth();
  const { updateSiteSettings } = useSiteSettings();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const {
    toastSetErrorNotification,
  } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const updateAuthAndNavigate = (response) => {
    const { accessToken, pointClickCare } = response.data;
    const decoded = jwtDecode(accessToken);
    setAuth({ accessToken });
    setPointClickCareAuth(pointClickCare);
    updateSiteSettings(decoded.siteIds);
  };

  const verifyCode = async () => {
    setLoading(true);
    try {
      const response = await axiosIntegrationInstance.get(
        `/pointClickCare/auth/callback?code=${code}`,
      );
      if (response.status === 200) {
        // Successful login if mfa not required
        updateAuthAndNavigate(response);
      }
      navigate('/home');
    } catch (error) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      verifyCode();
    }
  }, [code]);

  return (
    <Box
      sx={
        {
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
        }
      }
    >
      {loading ? (
        <CircularProgress />
      ) : null}
    </Box>
  );
}
