import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button as DialogButton,
  DialogActions,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { axiosAuthInstance, axiosErrorToUserMessage } from '../services/axios';
import validatePassword from '../services/validatePassword';
import Toast, { useToast } from '../components/useToast';
import CustomAppBar from './Appbar/CustomAppBar';

const initialFieldValues = {
  currentPassword: '',
  newPassword: '',
  verifyNewPassword: '',
};

export default function ChangePasswordPage() {
  const axiosAuth = useAxiosPrivate(axiosAuthInstance);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const {
    toastState,
    toastSetErrorNotification,
    toastSetSuccessNotification,
    toastOnClose,
  } = useToast();

  // useForm hook to manage form state
  const {
    values, handleInputChange, errors, setErrors,
  } = useForm(initialFieldValues);

  const handleChangePassword = async () => {
    errors.currentPassword = values.currentPassword ? '' : 'Current password is required';
    errors.newPassword = values.newPassword ? '' : 'New password is required';
    errors.verifyNewPassword = values.verifyNewPassword ? '' : 'Please confirm your new password';
    setErrors({ ...errors });

    if (!values.currentPassword || !values.newPassword || !values.verifyNewPassword) {
      toastSetErrorNotification('Please fill out all the required fields');
      return;
    }

    if (values.newPassword !== values.verifyNewPassword) {
      toastSetErrorNotification('New passwords do not match');
      return;
    }

    const result = validatePassword(values.newPassword);
    if (!result.isValid) {
      const detailedErrorMessage = result.errors.join(', ');
      toastSetErrorNotification(`${detailedErrorMessage}`);
      return;
    }

    if (values.currentPassword === values.newPassword) {
      toastSetErrorNotification('New password cannot be your old password');
      return;
    }

    await axiosAuth.post('/user/changePassword', JSON.stringify({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    }))
      .then(() => {
        setErrors({});
        setIsPasswordChanged(true);
        toastSetSuccessNotification('Password changed successfully.');
      })
      .catch((error) => toastSetErrorNotification(
        axiosErrorToUserMessage(error) || 'An error occurred. Please try again.',
      ));
  };

  if (isPasswordChanged) {
    return (
      <>
        <CustomAppBar name="Change Password" />
        <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="h6">
            Password changed successfully
          </Typography>
          <CheckIcon sx={{ fontSize: 60 }} />
        </Container>
      </>
    );
  }

  return (
    <>
      <CustomAppBar name="Change Password" />
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <PasswordInput
          label="Current Password"
          name="currentPassword"
          dataTestId="currentPasswordField"
          value={values.currentPassword}
          onChange={handleInputChange}
          error={errors.currentPassword}
        />
        <PasswordInput
          label="New Password"
          name="newPassword"
          value={values.newPassword}
          onChange={handleInputChange}
          error={errors.newPassword}
          dataTestId="newPasswordField"
        />
        <PasswordInput
          label="Verify New Password"
          name="verifyNewPassword"
          value={values.verifyNewPassword}
          onChange={handleInputChange}
          error={errors.verifyNewPassword}
          dataTestId="verifyNewPasswordField"
        />

        <Box sx={{ marginBottom: '0.2rem' }}>
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.8rem',
              marginLeft: '0.2rem',
              marginTop: '0.3rem',
              color: values.newPassword.length >= 8 ? 'green' : 'red',
            }}
          >
            Password should be at least 8 characters long
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: '0.8rem',
              marginLeft: '0.2rem',
              color: !/(.)\1{2}/.test(values.newPassword) ? 'green' : 'red',
            }}
          >
            Password must not exceed two repeated consecutive characters
          </Typography>
        </Box>

        <DialogActions sx={{ marginTop: '1rem' }}>
          <DialogButton data-testid="saveChangesButton" onClick={handleChangePassword} variant="contained" color="primary">Save changes</DialogButton>
        </DialogActions>
        <Toast
          open={toastState.open}
          severity={toastState.severity}
          text={toastState.text}
          onClose={toastOnClose}
        />
      </Container>
    </>
  );
}

// Reusable component for password input fields
function PasswordInput(props) {
  const {
    label,
    name,
    dataTestId,
    value,
    onChange,
    error,
  } = props;

  return (
    <Controls.Input
      label={label}
      name={name}
      data-testid={dataTestId}
      value={value}
      type="password"
      onChange={onChange}
      error={error}
      fullWidth
      margin="dense"
      required
    />
  );
}

PasswordInput.defaultProps = {
  error: null,
  dataTestId: '',
};

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};
