import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const initialState = {
  open: false,
  severity: 'success',
  text: '',
};

export function useToast() {
  const [toastState, setToastState] = useState(initialState);

  const toastOnClose = () => {
    setToastState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const toastSetSuccessNotification = (msg) => {
    setToastState({
      open: true,
      severity: 'success',
      text: msg,
    });
  };

  const toastSetErrorNotification = (msg) => {
    setToastState({
      open: true,
      severity: 'error',
      text: msg,
    });
  };

  return {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  };
}

export default function Toast(props) {
  const {
    open,
    severity,
    text,
    autoHideDuration,
    onClose,
    elevation,
    variant,
  } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <MuiAlert
        elevation={elevation}
        variant={variant}
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {text}
      </MuiAlert>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  elevation: PropTypes.number,
  variant: PropTypes.string,
};

Toast.defaultProps = {
  autoHideDuration: 5000,
  onClose: null,
  elevation: 6,
  variant: 'filled',
};
