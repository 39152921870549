import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import { validateSignUpToken, signUpUser } from '../services/auth/authServices';
import FormContainer from '../components/FormContainer';
import { axiosErrorToUserMessage } from '../services/axios';
import { useToast } from '../components/useToast';
import validatePassword from '../services/validatePassword';

const initialFieldValues = {
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
};

export default function FhxSignUp() {
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [tokenValid, setTokenValid] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [email, setEmail] = useState('');
  const { signupToken } = useParams();
  const navigate = useNavigate();
  const {
    toastSetErrorNotification,
  } = useToast();

  const {
    values, errors, setErrors, handleInputChange,
  } = useForm(initialFieldValues);

  useEffect(() => {
    const verifySignUpToken = async () => {
      try {
        const response = await validateSignUpToken(signupToken);
        if (response.username) {
          setEmail(response.username);
          setTokenValid(true);
          setResponseData(response);
        } else {
          setTokenValid(false);
        }
      } catch (error) {
        setTokenValid(false);
      }
    };

    verifySignUpToken();
  }, [signupToken]);

  const handleSubmit = async (event) => {
    setSignUpLoading(true);
    event.preventDefault();

    const errorMsg = {};
    const validPassword = validatePassword(values.password);
    if (!values.firstName) {
      errorMsg.firstName = 'First name is required';
    }
    if (!values.lastName) {
      errorMsg.lastName = 'Last name is required';
    }
    if (!values.password) {
      errorMsg.password = 'Password is required';
    } else if (!validPassword.isValid) {
      const detailedErrorMessage = validPassword.errors.join(', ');
      errorMsg.password = `${detailedErrorMessage}`;
    }
    if (values.password !== values.confirmPassword) {
      errorMsg.confirmPassword = 'Password does not match';
    }

    if (Object.keys(errorMsg).length > 0) {
      setErrors(errorMsg);
      setSignUpLoading(false);
      return;
    }

    try {
      const signUpResponse = await signUpUser(responseData, values, signupToken);
      if (signUpResponse.status === 201) {
        navigate('/multi-factor-signup', {
          state: {
            username: responseData.username,
            signUpToken: signUpResponse.data.mfaToken,
          },
        });
      } else {
        const errorMessage = axiosErrorToUserMessage(signUpResponse) || 'Authentication error.';
        toastSetErrorNotification(errorMessage);
      }
    } catch (error) {
      toastSetErrorNotification(error.message);
    } finally {
      setSignUpLoading(false);
    }
  };

  if (tokenValid === null) {
    return <div>Validating...</div>;
  }

  if (!tokenValid) {
    return (
      <div>
        <Typography variant="h4" component="h2">
          Error 404
        </Typography>
        <Controls.Button
          text="Go to Home Page"
          variant="contained"
          onClick={() => navigate('/')}
        />
      </div>
    );
  }

  return (

    <FormContainer title="Sign Up">
      <Typography component="h1" variant="h6" fontWeight="bold">
        {email}
      </Typography>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <Controls.Input
          fullWidth
          label="First Name"
          name="firstName"
          value={values.firstName}
          error={errors.firstName}
          onChange={handleInputChange}
          sx={{ margin: '10px 0px' }}
          data-testid="firstName"
        />
        <Controls.Input
          fullWidth
          label="Last Name"
          name="lastName"
          value={values.lastName}
          error={errors.lastName}
          onChange={handleInputChange}
          sx={{ margin: '10px 0px' }}
          data-testid="lastName"
        />
        <Controls.Input
          fullWidth
          label="Password"
          name="password"
          value={values.password}
          error={errors.password}
          onChange={handleInputChange}
          type="password"
          sx={{ margin: '10px 0px' }}
          data-testid="password"
        />
        <Controls.Input
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          value={values.confirmPassword}
          error={errors.confirmPassword}
          onChange={handleInputChange}
          type="password"
          sx={{ margin: '10px 0px' }}
          data-testid="confirmPassword"
        />

        <Controls.Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          text={signUpLoading ? 'Loading...' : 'Sign Up'}
          disabled={signUpLoading}
          sx={{ mt: 3, mb: 2, width: 1 }}
          data-testid="signup"
        />
      </Box>
    </FormContainer>
  );
}
