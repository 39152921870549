/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Controls } from '../components/controls/Controls';
import Form from '../components/useForm';
import Modal from '../components/Modal';

export default function ConfirmModal(props) {
  const {
    handleCloseConfirmModal,
    handleRejectConfirm,
    confirmDialogVisible,
    handleConfirmSubmit,
    title,
    modalText,
    confirmText,
    cancelText,
  } = props;

  return (
    <Modal
      isOpen={confirmDialogVisible}
      handleClose={handleCloseConfirmModal}
      title={title}
    >
      <Form>
        {modalText}
      </Form>
      <Box display="flex">
        <Controls.Button
          variant="contained"
          onClick={handleConfirmSubmit}
          sx={{ margin: '6px' }}
          text={confirmText}
          data-testid="confirmButton"
        />
        <Controls.Button
          variant="contained"
          onClick={handleRejectConfirm}
          sx={{ margin: '6px' }}
          text={cancelText}
          data-testid="cancelButton"
        />
      </Box>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  handleCloseConfirmModal: PropTypes.func.isRequired,
  handleRejectConfirm: PropTypes.func.isRequired,
  confirmDialogVisible: PropTypes.bool.isRequired,
  handleConfirmSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  modalText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  title: 'title',
  modalText: '',
  confirmText: 'YES',
  cancelText: 'No',
};
