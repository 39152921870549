import Cookie from 'universal-cookie';
import * as speechSDK from 'microsoft-cognitiveservices-speech-sdk';

export default class SpeechToTextService {
  constructor(apiClient) {
    this.api = apiClient;
  }

  async getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');

    if (speechToken === undefined) {
      try {
        const response = await this.api.getSpeechToTextToken();
        const { token, region } = response.data;
        cookie.set('speech-token', `${region}:${token}`, { maxAge: 540, path: '/' });
        return { authToken: token, region };
      } catch (err) {
        return { authToken: null, error: err.response.data };
      }
    } else {
      const speechTokenInfo = speechToken.split(':');
      return { authToken: speechTokenInfo[1], region: speechTokenInfo[0] };
    }
  }

  async getSpeechToTextRecognizer(language) {
    const { authToken, region } = await this.getTokenOrRefresh();
    const speechConfig = speechSDK.SpeechConfig.fromAuthorizationToken(authToken, region);
    speechConfig.enableDictation(); // allow special character recognition such as . ?...
    speechConfig.speechRecognitionLanguage = language;

    const audioConfig = speechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechSDK.SpeechRecognizer(speechConfig, audioConfig);
    return recognizer;
  }
}
