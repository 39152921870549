import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controls } from '../../../../components/controls/Controls';
import AppointmentTypeToIntakePlanMappingModal from './AppointmentTypeToIntakePlanMappingModal';

export default function AccuroAppointmentTypeSetting(props) {
  const {
    appointmentSetting,
    selectedPlans,
    refreshParent,
    setSuccessMessage,
    setErrorMessage,
  } = props;

  const [mainModalVisible, setMainModalVisible] = useState(false);

  return (
    <>
      <Controls.Button
        sx={{ margin: '7px 0' }}
        variant="contained"
        onClick={() => {
          setMainModalVisible(true);
        }}
        text="Edit Intake Plans"
      />
      <AppointmentTypeToIntakePlanMappingModal
        appointmentSetting={appointmentSetting}
        isOpen={mainModalVisible}
        handleClose={() => setMainModalVisible(false)}
        intakePlanOptions={selectedPlans}
        refreshParent={refreshParent}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </>
  );
}

AccuroAppointmentTypeSetting.propTypes = {
  selectedPlans: PropTypes.arrayOf(
    PropTypes.shape({
      actionListId: PropTypes.number,
      id: PropTypes.number,
      isIntakePlan: PropTypes.bool,
      languagesToTranslate: PropTypes.arrayOf(PropTypes.string),
      uniqueName: PropTypes.string,
    }),
  ).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  appointmentSetting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      intakeModuleId: PropTypes.number,
    }),
  ).isRequired,
};
