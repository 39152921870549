import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import CustomAppBar from '../../content/Appbar/CustomAppBar';

export default function IframePage() {
  const location = useLocation();
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    if (location.state && location.state.url) {
      setIframeSrc(location.state.url);
    }
  }, [location]);

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container sx={{ height: '100vh' }}>
        <Grid sx={{ width: 1 }}>
          <CustomAppBar siteSelectorOn={false} name="" />
        </Grid>
        <Grid sx={{ width: 1, padding: '10px 30px', height: '100%' }}>
          <iframe
            title="external-iframe"
            src={iframeSrc}
            style={{ width: '100%', height: '100%', border: 'none' }}
            allow="microphone"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
