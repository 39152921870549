import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Box, Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SiteSelector from '../SiteSelector';
import AdminMenu from './AdminMenu';
import ProfileMenu from './ProfileMenu';
import { useToast } from '../../components/useToast';
import RedirectToPageButton from '../RedirectToPageButton';
import usePermission from '../../hooks/usePermissions';

export default function CustomAppBar({
  siteSelectorOn, name,
}) {
  const {
    toastSetSuccessNotification,
    toastSetErrorNotification,
  } = useToast();
  const isMobile = useMediaQuery('(max-width:425px)');

  const {
    isAdmin,
    isCustomerAdmin,
    isAccuroAdmin,
    canCreateUsersForOwnSites,
  } = usePermission();

  // Check if either isAdmin or canCreateUsersForOwnSites can create users
  const canCreateUsersForSites = useMemo(
    () => isAdmin() || canCreateUsersForOwnSites(),
    [isAdmin, canCreateUsersForOwnSites],
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'transparent',
        boxShadow: '0',
        padding: isMobile ? 0 : '0 30px',
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' }, // stack on narrow screen, row on wide screen
        }}
      >
        <Box>
          {siteSelectorOn && <SiteSelector />}
          <Typography variant="h5" color="black">
            {name}
          </Typography>
        </Box>
        <Box>
          <RedirectToPageButton
            buttonText="Home"
            page="/home"
            key="homeButton"
          />
          <AdminMenu
            isAdmin={isAdmin()}
            isCustomerAdmin={isCustomerAdmin()}
            isAccuroAdmin={isAccuroAdmin()}
            canCreateUsersForSites={canCreateUsersForSites}
            toastSetSuccessNotification={toastSetSuccessNotification}
            toastSetErrorNotification={toastSetErrorNotification}
          />
          <ProfileMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  siteSelectorOn: PropTypes.bool,
  name: PropTypes.string,
};

CustomAppBar.defaultProps = {
  siteSelectorOn: false,
  name: '',
};
