import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem, OutlinedInput, Box,
} from '@mui/material';
import { Controls } from '../components/controls/Controls';

function CustomerDropDown({
  customers, values, handleInputChange, required,
}) {
  return (
    <Controls.Select
      data-testid="customerDropdown"
      label="Customers"
      labelId="customer-id-label"
      id="customer-id"
      value={values.customerIds}
      name="customerId"
      onChange={handleInputChange}
      input={<OutlinedInput id="select-single-customer" label="Customer Id" />}
      renderValue={(selected) => {
        const customer = customers.find((c) => c.id === selected);
        return customer ? <Box>{customer.name}</Box> : null;
      }}
      required={required}
    >
      {customers && customers.length > 0 && customers.map((customer) => (
        <MenuItem key={customer.id} value={customer.id}>
          {customer.name}
        </MenuItem>
      ))}
    </Controls.Select>
  );
}

CustomerDropDown.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  values: PropTypes.shape({
    customerIds: PropTypes.string,
  }),
  handleInputChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

CustomerDropDown.defaultProps = {
  values: {
    customerIds: '',
  },
  required: false,
};

export default CustomerDropDown;
