import React from 'react';
import {
  InputAdornment, OutlinedInput, CircularProgress,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

export default function SearchBar({
  value, onChange, placeholder, loading,
}) {
  const isMobile = useMediaQuery('(max-width:425px)');
  return (
    <OutlinedInput
      sx={{
        maxHeight: '44px',
        minWidth: '240px',
        width: isMobile ? '100%' : '20%',
        mt: isMobile ? 2 : 0,
      }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      endAdornment={(
        <InputAdornment position="end">
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <MagnifyingGlassIcon className="search-icon" />
          )}
        </InputAdornment>
      )}
    />
  );
}

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
  loading: false,
};
