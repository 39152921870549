import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { IconButton, Typography } from '@mui/material';
import Button from '../components/controls/Button';
import Modal from '../components/Modal';
import { useApiClient } from '../context/ApiClientContext';
import basicServiceHandler from '../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../services/axios';
import '../App.css';

function DeleteSiteButton(props) {
  const {
    site, refreshParent, setSuccessMessage, setErrorMessage,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const apiClient = useApiClient();

  const handleConfirmDelete = async () => {
    if (site.id) {
      basicServiceHandler(
        () => apiClient.deleteSite(site.id),
        () => {
          refreshParent();
          setSuccessMessage('Site deleted successfully');
        },
        (error) => {
          setErrorMessage(
            axiosErrorToUserMessage(error) || 'Error deleting site',
          );
        },
      );
      setDialogOpen(false);
    }
  };

  return (
    <>
      <IconButton onClick={() => setDialogOpen(true)}>
        <TrashIcon className="table-tool-icon" />
      </IconButton>
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Delete Site"
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Please confirm if you want to delete the site with name:
          {' '}
          {site.name}
        </Typography>
        <Button
          text="Cancel"
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(false)}
          sx={{ marginRight: '1rem' }}
        />
        <Button
          text="Delete"
          variant="contained"
          color="primary"
          onClick={handleConfirmDelete}
        />
      </Modal>
    </>
  );
}

DeleteSiteButton.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default DeleteSiteButton;
