import React, { useState } from 'react';
import {
  Box, AppBar, Avatar, IconButton, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

export default function IntakeReviewPageHeader({
  patientID, patientAge, patientSex, onBack, headerLogo,
}) {
  const [headerDetailsVisible, setHeaderDetailsVisible] = useState(true);
  const isMobile = useMediaQuery('(max-width:425px)');

  const toggleHeaderDetails = () => {
    setHeaderDetailsVisible(!headerDetailsVisible);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: '#DBE2F4',
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50px',
      }}
    >
      <IconButton onClick={onBack} sx={{ position: 'absolute', left: 0 }}>
        <Avatar variant="square">
          <img
            alt="Home Page"
            src={headerLogo}
            width="100%"
          />
        </Avatar>
      </IconButton>
      {isMobile && (
        <IconButton
          onClick={toggleHeaderDetails}
          sx={{ position: 'absolute', right: 0 }}
        >
          {headerDetailsVisible ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
        </IconButton>
      )}

      <Box
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          display: headerDetailsVisible ? 'flex' : 'none',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        {patientID && (
          <Typography sx={{ color: '#000' }}>
            <span style={{ fontWeight: 500, fontSize: '0.875rem' }}>MRN: </span>
            {patientID}
          </Typography>
        )}
        {patientAge && (
          <Typography sx={{ color: '#000' }}>
            <span style={{ fontWeight: 500, fontSize: '0.875rem' }}>Age: </span>
            {patientAge}
          </Typography>
        )}
        {patientSex && (
          <Typography sx={{ color: '#000' }}>
            <span style={{ fontWeight: 500, fontSize: '0.875rem' }}>Sex: </span>
            {patientSex}
          </Typography>
        )}
      </Box>
    </AppBar>
  );
}

IntakeReviewPageHeader.propTypes = {
  onBack: PropTypes.func.isRequired,
  patientID: PropTypes.string.isRequired,
  patientAge: PropTypes.string,
  patientSex: PropTypes.string,
  headerLogo: PropTypes.string.isRequired,
};

IntakeReviewPageHeader.defaultProps = {
  patientAge: '',
  patientSex: '',
};
