import React, { useState } from 'react';
import {
  IconButton, Menu, MenuItem, Typography,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { LockClosedIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useLogout, useRevokePointClickCareAccessToken } from '../../services/auth/authServices';

/**
 * This component renders dropdown options related to user,
 * such as logging out and changing password
 */

function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const logout = useLogout();
  const revoke = useRevokePointClickCareAccessToken();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePasswordClick = () => {
    navigate('/user/changePassword');
  };

  const handleLogoutClick = () => {
    logout();
    revoke();
    handleClose();
  };

  return (
    <>
      <IconButton
        size="large"
        edge="end"
        onClick={handleMenu}
        sx={{ borderRadius: '5%', color: 'black' }}
        data-testid="profileMenuButton"
      >
        <Typography sx={{ fontWeight: '500' }}>Profile</Typography>
        <KeyboardArrowDownRoundedIcon />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleChangePasswordClick} data-testid="changePasswordItem">
          <LockClosedIcon className="nav-bar-icon" />
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogoutClick} data-testid="logoutItem">
          <ArrowRightEndOnRectangleIcon className="nav-bar-icon" />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default ProfileMenu;
