import React, {
  useState, useEffect, createContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { axiosCustomerInstance } from '../services/axios';
import compareArrays from '../utils/compareArrays';

const SitesDataContext = createContext({});

export function SiteSettingsProvider({ children }) {
  const [activeSiteId, setActiveSiteId] = useState(localStorage.getItem('activeSite') || '');
  const [accessibleSiteInfos, setAccessibleSiteInfos] = useState([]);
  const { accessibleSiteIds, setAccessibleSiteIds } = useAuth();
  // Need to use useAxiosPrivate instead of apiClient to avoid circular dependency for context
  const axiosSitePrivate = useAxiosPrivate(axiosCustomerInstance);

  /**
 * Merges two arrays of settings (`settings` and `defaultSettings`) into a single array.
 * - If a setting exists in both `settings` and `defaultSettings`, the value from `settings`
 *   will override the one from `defaultSettings`.
 * - Each setting is identified by a composite key of `name` and `category`, ensuring uniqueness.
 *
 * Example:
 * const settings = [
 *   { name: 'intakePlans', category: 'medical', value: '123,456' },
 *   { name: 'customTheme', category: 'intake', value: 'dark' }
 * ];
 *
 * const defaultSettings = [
 *   { name: 'intakePlans', category: 'medical', value: '789' },  // Will be overridden
 *   { name: 'localization', category: 'intake', value: 'en' }    // Will be preserved
 * ];
 *
 * const merged = mergeSettings(settings, defaultSettings);
 * // Result:
 * // [
 * //   { name: 'intakePlans', category: 'medical', value: '123,456' }, // From settings
 * //   { name: 'localization', category: 'intake', value: 'en' },      // From defaultSettings
 * //   { name: 'customTheme', category: 'intake', value: 'dark' },     // From settings
 * // ]
 */
  const mergeSettings = (settings, defaultSettings) => {
    // If settings are empty or undefined, return defaultSettings
    if (!settings || settings.length === 0) {
      return defaultSettings;
    }
    if (!defaultSettings || defaultSettings.length === 0) {
      return settings;
    }

    // Combine defaultSettings and settings into one array, with defaultSettings first
    // Use reduce to accumulate the settings into a Map, keyed by 'name:category'.
    // If a setting exists in both arrays, the one from settings (which comes later) will override
    // the one from defaultSettings due to the order of concatenation.
    const mergedMap = defaultSettings.concat(settings).reduce((map, setting) => {
      const key = `${setting.name}:${setting.category}`;
      // Settings from 'settings' array will override those from 'defaultSettings'
      // due to the order of concatenation
      map.set(key, { ...setting });
      return map;
    }, new Map());
    // Convert the Map back to an array of settings
    return Array.from(mergedMap.values());
  };

  const fetchSite = async (siteId) => {
    try {
      const response = await axiosSitePrivate.get(`/${siteId}`);
      const {
        id, name, settings, defaultSettings,
      } = response.data;
      const mergedSettings = mergeSettings(settings, defaultSettings);
      return {
        id,
        name,
        settings: mergedSettings,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateActiveSiteInfo = (activeSiteInfo) => {
    // Update accessibleSiteInfos with the new active site data
    setAccessibleSiteInfos((prevInfos) => {
      const siteExists = prevInfos.some((s) => s.id === activeSiteInfo.id);
      if (siteExists) {
        // Replace the existing site info
        return prevInfos.map((s) => (s.id === activeSiteInfo.id ? activeSiteInfo : s));
      }
      // Add the new site info if it doesn't exist
      return [...prevInfos, activeSiteInfo];
    });
  };

  const updateSiteSettings = async (siteIds) => {
    const results = await Promise.allSettled(
      siteIds.map((siteId) => fetchSite(siteId)),
    );
    const sites = results.filter((site) => site.status === 'fulfilled').map((site) => site.value);
    const validSiteIds = sites.map((site) => site.id);

    const localStorageActiveSite = localStorage.getItem('activeSite');
    let newActiveSiteId = localStorageActiveSite;

    // Check if the current active site is not in the list of valid site ids
    if (!validSiteIds.includes(localStorageActiveSite)) {
      newActiveSiteId = validSiteIds[0] || '';
      // Only update the localStorage and state if the active site has changed
      if (newActiveSiteId !== localStorageActiveSite) {
        localStorage.setItem('activeSite', newActiveSiteId);
        setActiveSiteId(newActiveSiteId);
      }
    } else {
      setActiveSiteId(localStorageActiveSite);
    }

    // Extract and update the active site info just once
    const activeSiteInfo = sites.find((site) => site.id === newActiveSiteId);
    if (activeSiteInfo) {
      updateActiveSiteInfo(activeSiteInfo); // Pass the active site info once
    }
    // Check if the list of accessible site IDs has changed. Update if necessary.
    if (!compareArrays(validSiteIds, accessibleSiteIds)) {
      setAccessibleSiteIds(validSiteIds);
      localStorage.setItem('accessibleSiteIds', JSON.stringify(validSiteIds));
    }
  };

  const showAppointmentDatesForSite = (siteId) => {
    const activeSiteInfo = accessibleSiteInfos.find(
      (site) => site.id === siteId,
    );
    if (!activeSiteInfo) return false;

    const smsMessageSetting = activeSiteInfo.settings.find(
      (setting) => setting.name === 'smsMessage',
    );
    return smsMessageSetting?.value.includes('{{appointment_date}}') && smsMessageSetting?.value.includes('{{appointment_time}}');
  };

  useEffect(() => {
    const updateAccessibleSiteInfos = async (siteIds) => {
      // using Promise.allSettled() ensures that the whole operation is not failed,
      // if one of the input promises fails.
      const results = await Promise.allSettled(
        siteIds.map((siteId) => fetchSite(siteId)),
      );
      const sites = results.filter((site) => site.status === 'fulfilled').map((site) => site.value);
      setAccessibleSiteInfos(sites);
    };
    updateSiteSettings(accessibleSiteIds);
    updateAccessibleSiteInfos(accessibleSiteIds);
  }, [accessibleSiteIds]);

  // useEffect to fetch data for active site when activeSiteId changes
  useEffect(() => {
    const fetchAndUpdateSiteInfo = async () => {
      if (activeSiteId) {
        const siteInfo = await fetchSite(activeSiteId);
        updateActiveSiteInfo(siteInfo);
      }
    };

    fetchAndUpdateSiteInfo(); // Call the async function when activeSiteId changes
  }, [activeSiteId]);

  // Use memoization to avoid unnecessary re-renders
  const siteSettingsState = useMemo(() => ({
    activeSiteId,
    setActiveSiteId,
    accessibleSiteInfos,
    updateSiteSettings,
    showAppointmentDatesForSite,
  }), [
    activeSiteId,
    setActiveSiteId,
    accessibleSiteInfos,
  ]);

  return (
    <SitesDataContext.Provider
      value={siteSettingsState}
    >
      {children}
    </SitesDataContext.Provider>
  );
}

SiteSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SitesDataContext;
