import React from 'react';
import { Box, Container } from '@mui/material';
import CreateNewUserForm from './CreateNewUserForm';
import CustomAppBar from './Appbar/CustomAppBar';
import Toast, { useToast } from '../components/useToast';

export default function CreateNewUserPage() {
  const {
    toastState,
    toastSetErrorNotification,
    toastSetSuccessNotification,
    toastOnClose,
  } = useToast();

  return (
    <Box>
      <CustomAppBar name="Create a new user" />
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <CreateNewUserForm
          setSuccessMessage={toastSetSuccessNotification}
          setErrorMessage={toastSetErrorNotification}
        />
      </Container>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </Box>
  );
}
