import React, { useState, useEffect } from 'react';
import {
  Avatar, Typography, Box, Grid, Paper,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Controls } from '../components/controls/Controls';
import { validatePasswordResetToken, resetPassword } from '../services/auth/authServices';
import { useToast } from '../components/useToast';
import validatePassword from '../services/validatePassword';

function ResetPassword() {
  const { token } = useParams();
  const [tokenValid, setTokenValid] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { toastSetErrorNotification } = useToast();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await validatePasswordResetToken(token);
        if (response.status === 200) {
          setTokenValid(true);
        } else {
          setTokenValid(false);
        }
      } catch (error) {
        setTokenValid(false);
      }
    };

    verifyToken();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      toastSetErrorNotification('Passwords do not match');
      return;
    }

    const result = validatePassword(newPassword);
    if (!result.isValid) {
      const detailedErrorMessage = result.errors.join(', ');
      toastSetErrorNotification(`${detailedErrorMessage}`);
      return;
    }

    try {
      await resetPassword(token, newPassword);
      setPasswordChanged(true);
    } catch (error) {
      toastSetErrorNotification('Couldn\'t change password');
    }
  };

  if (tokenValid === null) {
    return <div>Validating token...</div>;
  }

  if (!tokenValid) {
    return (
      <div>
        <Typography variant="h4" component="h2">
          Error 404
        </Typography>
        <Controls.Button
          text="Go to Home Page"
          variant="contained"
          onClick={() => navigate('/')}
        />
      </div>
    );
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        backgroundImage: 'url(/assets/images/hxmanager_login.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <Grid
        item
        component={Paper}
        elevation={6}
        sx={{
          width: '100%',
          maxWidth: '500px',
          height: 'fitContent',
          padding: '20px',
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ width: '84px', height: '84px', backgroundColor: 'transparent' }}>
            <img alt="FirstHx Logo" src="/assets/images/FirstHx_symbol_colour.jpg" width="100%" />
          </Avatar>

          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>

          {passwordChanged ? (
            <>
              <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>
                Password changed successfully.
              </Typography>
              <Controls.Button
                text="Go to Login"
                variant="contained"
                onClick={() => navigate('/')}
              />
            </>
          ) : (
            <Box component="form" noValidate={false} sx={{ mt: 1 }} onSubmit={handleSubmit}>
              <Controls.Input
                fullWidth
                label="New Password"
                name="newPassword"
                type="password"
                sx={{ margin: '10px 0px' }}
                data-testid="newPassword"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />

              <Controls.Input
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                sx={{ margin: '10px 0px' }}
                data-testid="confirmPassword"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />

              {newPassword && confirmPassword && newPassword !== confirmPassword && (
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '0.8rem',
                    marginLeft: '0.2rem',
                    marginTop: '0.3rem',
                    color: 'red',
                  }}
                >
                  Passwords do not match
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.8rem',
                  marginLeft: '0.2rem',
                  marginTop: '0.3rem',
                  color: newPassword.length >= 8 ? 'green' : 'red',
                }}
              >
                Password should be at least 8 characters long
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.8rem',
                  marginLeft: '0.2rem',
                  color: !/(.)\1{2}/.test(newPassword) ? 'green' : 'red',
                }}
              >
                Password must not exceed two repeated consecutive characters
              </Typography>

              <Controls.Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                text="Submit"
                data-testid="submitButton"
              />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
