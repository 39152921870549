import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import Toast, { useToast } from '../../components/useToast';
import { useApiClient } from '../../context/ApiClientContext';
import { axiosErrorToUserMessage } from '../../services/axios';
import '../../App.css';

export default function CopyTraceButton(props) {
  const {
    mkey,
  } = props;

  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);

  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const onClick = async () => {
    setIsLoading(true);
    try {
      const { intakeMetaInfo, steps } = (await apiClient.getIntakeTrace(mkey)).data;
      const module = await apiClient.getModule(intakeMetaInfo.intakePlanModuleId)
        .then((result) => result.data)
        .catch(() => null);
      const moduleName = module ? module.uniqueName : intakeMetaInfo.intakePlanModuleId;
      const tableToCopy = `"mkey: ${mkey}\nIntake Plan Module: ${moduleName}"\n${steps}`;
      navigator.clipboard.writeText(tableToCopy);
      toastSetSuccessNotification('Trace copied to clipboard');
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error)
          || 'An error occurred when fetching the trace.',
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <Tooltip title="Copy trace" arrow>
        <IconButton
          onClick={onClick}
          data-testid="copyTraceIcon"
        >
          {isLoading ? <CircularProgress size={24} /> : <DocumentDuplicateIcon className="table-tool-icon" />}
        </IconButton>
      </Tooltip>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </>
  );
}

CopyTraceButton.propTypes = {
  mkey: PropTypes.string.isRequired,
};
