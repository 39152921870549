import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, CircularProgress, Tooltip, Typography,
} from '@mui/material';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import Button from '../../components/controls/Button';
import Modal from '../../components/Modal';
import { useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../../services/axios';

export default function ResendSMSButton({
  mkey, siteId, phoneNumber, setSuccessMessage, setErrorMessage,
}) {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const apiClient = useApiClient();

  const resendSMS = async () => {
    setLoading(true);
    try {
      await basicServiceHandler(
        () => apiClient.resendSMS(mkey, siteId),
        () => {
          setSuccessMessage('SMS successfully resent');
        },
        (error) => {
          const errorMessage = axiosErrorToUserMessage(error) || 'An error occurred while resending the SMS.';
          setErrorMessage(errorMessage);
        },
      );
    } finally {
      setLoading(false);
      setDialogOpen(false);
    }
  };

  const onClick = () => {
    setDialogOpen(true);
  };

  const handleConfirmResend = () => {
    resendSMS();
  };

  return (
    <>
      <Tooltip title="Resend SMS" arrow>
        <span>
          <IconButton onClick={onClick} data-testid="resendSMSIcon" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : <SendToMobileOutlinedIcon fontSize="small" />}
          </IconButton>
        </span>
      </Tooltip>
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Confirm Resend SMS"
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Are you sure you want to resend the SMS to
          {' '}
          {phoneNumber}
          ?
        </Typography>
        <Button
          text="Cancel"
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(false)}
          sx={{ marginRight: '1rem' }}
          data-testid="cancelButton"
        />
        <Button
          text="Confirm"
          variant="contained"
          color="primary"
          onClick={handleConfirmResend}
          loading={loading}
          data-testid="confirmButton"
        />
      </Modal>
    </>
  );
}

ResendSMSButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
