/* eslint-disable */
const basicServiceHandler = (service, successCB, failCB, finalCB) => {
  return new Promise((resolve, reject) => {
    service()
      .then((result) => {
        if (!result) return resolve();
        if (
          result.status === 200
          || result.status === 201
          || result.status === 202
        ) {
          resolve(successCB(result));
        } else {
          reject(failCB(result));
        }
      })
      .catch((error) => {
        failCB(error);
      })
      .finally(() => {
        if (finalCB) {
          finalCB();
        }
      });
  });
};

export default basicServiceHandler;
