import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography, Tooltip } from '@mui/material';
import { TrashIcon } from '@heroicons/react/24/outline';
import Button from '../../components/controls/Button';
import Modal from '../../components/Modal';
import { useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../../services/axios';
import { useIntakeData } from '../../context/IntakeDataContext';
import '../../App.css';

function DeleteOnePiiButton(props) {
  const {
    mkey, patientId, setSuccessMessage, setErrorMessage,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const {
    fetchIntakeData,
  } = useIntakeData();

  const apiClient = useApiClient();

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    basicServiceHandler(
      () => apiClient.deleteIntake(mkey),
      () => {
        fetchIntakeData();
        setSuccessMessage('Deleted');
      },
      (error) => {
        setErrorMessage(
          axiosErrorToUserMessage(error) || 'Error deleting intake',
        );
      },
      () => {
        setIsLoading(false);
        setDialogOpen(false);
      },
    );
  };

  return (
    <>
      <Tooltip title="Delete" arrow>
        <IconButton data-testid="DeleteIcon" onClick={() => setDialogOpen(true)}>
          <TrashIcon className="table-tool-icon" />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title={`Delete ${patientId}`}
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Are you sure you want to delete
          {' '}
          <b>
            {patientId}
          </b>
          ?
          <br />
          This cannot be undone.
        </Typography>
        <Button
          text="Cancel"
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(false)}
          sx={{ marginRight: '1rem' }}
          data-testid="cancelButton"
        />
        <Button
          text="Confirm"
          variant="contained"
          color="primary"
          onClick={handleConfirmDelete}
          loading={loading}
          data-testid="confirmButton"
        />
      </Modal>
    </>
  );
}
export default DeleteOnePiiButton;

DeleteOnePiiButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

DeleteOnePiiButton.defaultProps = {
  patientId: '',
};
