import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Form, { useForm } from '../../../components/useForm';
import { Controls } from '../../../components/controls/Controls';
import Modal from '../../../components/Modal';
import AccuroPatientTable from './AccuroPatientTable';
import useSiteSettings from '../../../hooks/useSiteSettings';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../context/ApiClientContext';
import basicServiceHandler from '../../../services/basicServiceHandler';

const initialAccuroFieldValues = {
  patientHealthNumber: '',
};

export default function AccuroModal(props) {
  const { setSuccessMessage, setErrorMessage, integrationSettings } = props;
  const [dialogVisible, setDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState(null);
  const apiClient = useApiClient();
  const { activeSiteId } = useSiteSettings();
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialAccuroFieldValues);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (values.patientHealthNumber) {
      setLoading(true);
      const { patientHealthNumber } = values;
      basicServiceHandler(
        () => apiClient.searchAccuroPatient(
          patientHealthNumber,
          activeSiteId,
        ),
        (response) => { setPatients(response.data.response.contents); },
        (error) => setErrorMessage(convertAxiosErrorToUserFacingMessage(error)),
      );
    } else {
      setErrorMessage('health number required');
    }
    setLoading(false);
  };

  useEffect(() => {
  }, [initialAccuroFieldValues]);

  const handleClose = () => {
    setDialogVisible(false);
    setErrors({});
  };
  return (
    <>
      <Controls.Button
        sx={{ margin: '20px 0 20px 10px' }}
        variant="outlined"
        onClick={() => {
          setDialogVisible(true);
        }}
        text="Accuro"
      />
      <Modal
        isOpen={dialogVisible}
        size="lg"
        handleClose={handleClose}
        title="Accuro"
      >
        <Form onSubmit={handleSearch}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            rowSpacing={1}
          >
            <Controls.Input
              label="Health Number"
              name="patientHealthNumber"
              value={values.patientHealthNumber}
              error={errors.patientHealthNumber}
              onChange={handleInputChange}
              style={{ maxWidth: '500px' }}
              sx={{
                '& .MuiInputBase-root': {
                  width: '50%',
                },
                '& .MuiFormControl-root': {
                  width: '70%',
                },
              }}
              data-testid="healthNumberSearchBar"
            />
            <Controls.Button
              variant="contained"
              type="submit"
              sx={{ margin: '6px' }}
              loading={loading}
              text="Search Patient"
              data-testid="searchPatientButton"
            />
          </Grid>
          <AccuroPatientTable
            patients={patients}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            integrationSettings={integrationSettings}
          />
        </Form>
      </Modal>
    </>
  );
}

AccuroModal.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integrationSettings: PropTypes.object.isRequired,
};
