import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, TableRow, TableCell, Link as MuiLink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import useTable from '../components/useTable';

const header = [
  { id: 'name', label: 'Name' },
  { id: 'sites', label: 'Sites' },
];

export default function CustomerInfoTable(props) {
  const { data } = props;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    currentPageDataSorted,
  } = useTable(data, header, { fn: (items) => items });

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {data && currentPageDataSorted().map((customer) => (
            <TableRow
              key={customer.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <MuiLink component={Link} to={`/customer/${customer.id}`} data-testid={customer.id} sx={{ color: 'inherit' }}>
                  {customer.name}
                </MuiLink>
              </TableCell>
              <TableCell>
                <ul>
                  {customer.sites.map((site) => (
                    <li key={site.id}>{site.name}</li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}

CustomerInfoTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
