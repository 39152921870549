import React, { useState } from 'react';
import {
  TableRow,
  // TableBody,
  TableCell,
  MenuItem,
  Grid,
  Box,
  Chip,
  IconButton,
  TableBody,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import { Controls } from '../../../../components/controls/Controls';
import useTable from '../../../../components/useTable';
import AddAppointmentTypeModal from './AddAppointmentTypeModal';
import { convertAxiosErrorToUserFacingMessage, useApiClient } from '../../../../context/ApiClientContext';
import basicServiceHandler from '../../../../services/basicServiceHandler';

const header = [
  { id: 'appointmentTypeID', label: 'Appointment Type ID' },
  { id: 'appointmentType', label: 'Appointment Type' },
  { id: 'intakePlan', label: 'Intake Plan' },
  { id: 'deleteType', label: 'Remove' },
];

export default function AppointmentTypeToIntakePlanMappingModal(props) {
  const {
    appointmentSetting,
    isOpen,
    handleClose,
    intakePlanOptions,
    refreshParent,
    setSuccessMessage,
    setErrorMessage,
  } = props;
  const [addAppointmentTypeModalVisible, setAddAppointmentTypeModalVisible] = useState(false);
  const { siteId } = useParams();
  const apiClient = useApiClient();

  const [appointmentPlanPairs, setAppointmentPlanPairs] = useState(appointmentSetting);
  const {
    TblContainer,
    TblHead,
    currentPageDataSorted,
  } = useTable(appointmentPlanPairs, header, { fn: (items) => items });

  const handleCloseAddAppointmentTypeModal = () => {
    setAddAppointmentTypeModalVisible(false);
  };

  const openAddAppointmentTypeModal = () => {
    setAddAppointmentTypeModalVisible(true);
  };

  const deleteType = (id) => {
    setAppointmentPlanPairs(appointmentPlanPairs.filter((item) => item.id !== id));
  };

  const handleSelectChange = (event) => {
    const newSelectedPlan = event.target.value.option;
    const newSelected = appointmentPlanPairs.map((obj) => {
      if (obj.name === event.target.name) {
        return { ...obj, intakeModuleId: newSelectedPlan.id };
      }
      return obj;
    });
    setAppointmentPlanPairs(newSelected);
  };

  const handleSave = () => {
    basicServiceHandler(
      () => apiClient.updateSiteSetting(siteId, { name: 'accuroAppointmentType', value: JSON.stringify(appointmentPlanPairs), category: 'api' }),
      () => {
        refreshParent();
        setSuccessMessage('Successfully updated setting');
      },
      (error) => {
        setErrorMessage(convertAxiosErrorToUserFacingMessage(error));
      },
    );
  };

  return (
    <>
      <AddAppointmentTypeModal
        isOpen={addAppointmentTypeModalVisible}
        handleClose={handleCloseAddAppointmentTypeModal}
        appointmentPlanPairs={appointmentPlanPairs}
        setAppointmentPlanPairs={setAppointmentPlanPairs}
      />
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        size="md"
        title="Accuro Appointment Type"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          rowSpacing={1}
        >
          <Controls.Button
            text="Add new type"
            onClick={openAddAppointmentTypeModal}
          />
          <Controls.Button
            text="Save"
            onClick={handleSave}
          />
        </Grid>
        <TblContainer>
          <TblHead />
          <TableBody>
            {appointmentPlanPairs && currentPageDataSorted().map((appointmentType) => (
              <TableRow key={appointmentType.id}>
                <TableCell>
                  {appointmentType.id}
                </TableCell>
                <TableCell>
                  {appointmentType.name}
                </TableCell>
                <TableCell>
                  <Controls.Select
                    sx={{ minWidth: '400px', maxWidth: '600px' }}
                    label="Value"
                    name={appointmentType.name}
                    value={appointmentPlanPairs}
                    onChange={handleSelectChange}
                    renderValue={() => {
                      if (appointmentType.intakeModuleId) {
                        const plan = intakePlanOptions.find(
                          (item) => item.id === appointmentType.intakeModuleId,
                        );
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            <Chip key={appointmentType.type} label={`${plan.referenceCode} [${plan.moduleName}]`} />
                          </Box>
                        );
                      }
                      return <Box />;
                    }}
                  >
                    {intakePlanOptions && intakePlanOptions.map((option) => (
                      <MenuItem key={option.referenceCode} value={{ option }}>
                        { `${option.referenceCode} [${option.moduleName}]` }
                      </MenuItem>
                    ))}
                  </Controls.Select>
                </TableCell>
                {appointmentType.id !== 0 && appointmentType.name !== 'default'
                  ? (
                    <TableCell>
                      <IconButton onClick={() => deleteType(appointmentType.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )
                  : (<TableCell />)}
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Modal>
    </>
  );
}

AppointmentTypeToIntakePlanMappingModal.propTypes = {
  appointmentSetting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      intakeModuleId: PropTypes.number,
    }),
  ).isRequired,
  intakePlanOptions: PropTypes.arrayOf(
    PropTypes.shape({
      actionListId: PropTypes.number,
      id: PropTypes.number,
      isIntakePlan: PropTypes.bool,
      languagesToTranslate: PropTypes.arrayOf(PropTypes.string),
      uniqueName: PropTypes.string,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};
