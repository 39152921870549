/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useForm } from '../components/useForm';
import Modal from '../components/Modal';
import EditSettingsForm from '../content/EditSettingsForm';
import EditSettingsOptions from '../content/EditSettingsOptions';
import { Controls } from '../components/controls/Controls';
import defaultUnsetSettings from '../site/DefaultUnsetSettings';

const initialFieldValues = {
  name: '',
  category: '',
  value: '',
};

export default function CustomerSettingsPane(props) {
  const {
    onSettingSave, category, subCategory,
    customer, refreshParent, setErrorMessage, setSuccessMessage,
  } = props;

  const [configuredSettings, setConfiguredSettings] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [unconfiguredSettings, setUnconfiguredSettings] = useState([]);

  const { values, setValues, handleInputChange } = useForm(initialFieldValues);

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setValues(initialFieldValues);
  };

  const handleSettingSave = () => {
    setIsEditModalOpen(false);
    onSettingSave(values);
    setValues(initialFieldValues);
  };

  /**
 * Fetches settings for the selected category.
 *
 * If there are no customer settings or the necessary subcategory selection is missing,
 * it returns an empty array. Otherwise, it filters the customer settings based on
 * the category and optionally the subcategory.
 */
  const fetchSettingsForSelectedCategory = () => {
    // If customer settings are not present, return an empty array.
    if (!customer.settings) {
      return [];
    }

    // If neither category nor subcategory is selected, return all settings.
    if (!category && !subCategory) {
      return customer.settings;
    }

    // Filter settings based on category and optionally subcategory.
    return customer.settings.filter((setting) => {
      const isCategoryMatch = category ? setting.category === category.id : true;
      const isSubCategoryMatch = subCategory ? setting.name.includes(subCategory.id) : true;
      return isCategoryMatch && isSubCategoryMatch;
    });
  };

  /**
 * Fetches unconfigured settings for the current category.
 *
 * Filters the default settings for a category by excluding those already set.
 * In case of subcategories, it further filters based on the subcategory selection.
 */
  const fetchUnconfiguredSettings = (settings) => {
    if (!category) {
      return [];
    }

    const allSettingsForCategory = defaultUnsetSettings[category.id];
    const setSettingsNames = new Set(settings.map((setting) => setting.name));

    // Filter settings based on whether they are set and optionally subcategory.
    return allSettingsForCategory.filter((setting) => {
      const isNotSet = !setSettingsNames.has(setting);
      const isSubCategoryMatch = subCategory ? setting.includes(subCategory.id) : true;
      return isNotSet && isSubCategoryMatch;
    });
  };

  useEffect(() => {
    const settings = fetchSettingsForSelectedCategory();
    setConfiguredSettings(settings);
    setUnconfiguredSettings(fetchUnconfiguredSettings(settings));
  }, [customer, category, subCategory]);

  if (!customer) {
    return null;
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <div style={{ width: '100%' }}>
        {configuredSettings.map((setting, index) => (
          <Grid container spacing={2} key={`${setting.name}-${index}`} style={{ marginBottom: '30px', marginTop: '10px' }}>
            <Grid item>
              <Controls.Input
                value={setting.name}
                name="name"
                label="Name"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item>
              <EditSettingsOptions
                setValues={setValues}
                handleSaveClick={handleSettingSave}
                handleEditClick={() => {
                  setIsEditModalOpen(true);
                  setValues(setting);
                }}
                values={setting}
                refreshParent={refreshParent}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                handleInputChange={handleInputChange}
                contextType="customer"
              />
            </Grid>
          </Grid>
        ))}
        {unconfiguredSettings.map((setting, index) => (
          <Grid container spacing={2} key={`${setting.name}-${index}`} style={{ marginBottom: '30px', marginTop: '10px' }}>
            <Grid item>
              <Controls.Input
                value={setting}
                name="name"
                label="Name"
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item>
              <EditSettingsOptions
                setValues={setValues}
                handleSaveClick={handleSettingSave}
                handleEditClick={() => {
                  setIsEditModalOpen(true);
                  setValues({ name: setting, category: category.id, value: values.value || '' });
                }}
                values={{ name: setting, category: category.id, value: '' }}
                refreshParent={refreshParent}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                handleInputChange={handleInputChange}
                contextType="customer"
              />
            </Grid>
          </Grid>
        ))}
      </div>
      <Modal isOpen={isEditModalOpen} handleClose={handleModalClose}>
        <Grid container>
          <Grid item xs={6}>
            <EditSettingsForm
              handleInputChange={handleInputChange}
              values={values}
              handleSaveClick={handleSettingSave}
            />
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

CustomerSettingsPane.propTypes = {
  onSettingSave: PropTypes.func.isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  customer: PropTypes.shape({
    settings: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
  }),
  refreshParent: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  subCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

CustomerSettingsPane.defaultProps = {
  customer: null,
  subCategory: null,
};
