import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToast } from '../components/useToast';
import Form, { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import Modal from '../components/Modal';
import CustomerDropDown from '../customers/CustomerDropdown';
import basicServiceHandler from '../services/basicServiceHandler';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { axiosErrorToUserMessage, axiosCustomerInstance } from '../services/axios';
import usePermission from '../hooks/usePermissions';

const initialFieldValues = {
  name: '',
  settings: [],
  customerId: '',
};

export default function CreateNewSiteButton(props) {
  const {
    refreshParent,
    setSuccessMessage,
    setErrorMessage,
  } = props;
  const [open, setOpen] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const { isAdmin } = usePermission();
  const {
    toastSetErrorNotification, toastSetSuccessNotification,
  } = useToast();

  const axiosSitePrivate = useAxiosPrivate(axiosCustomerInstance);

  const fetchCustomers = async () => {
    try {
      const allCustomers = isAdmin() ? await axiosSitePrivate.get('/customer/getAllCustomers')
        : await axiosSitePrivate.get('/customer/assigned');
      setCustomerData(allCustomers.data);
      toastSetSuccessNotification('Successfully fetched customer data.');
    } catch (error) {
      toastSetErrorNotification('Error fetching customer data.');
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const {
    values,
    handleInputChange,
    setValues,
  } = useForm(initialFieldValues);

  const handleClose = () => {
    setOpen(false);
  };

  const createSite = async () => {
    basicServiceHandler(
      () => axiosSitePrivate.post('/', values),
      (response) => {
        setSuccessMessage('Site created successfully.');
        setValues(initialFieldValues);
        refreshParent();
        return response.data.id;
      },
      (error) => {
        setErrorMessage(
          axiosErrorToUserMessage(error),
        );
      },
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    createSite();
    handleClose();
  };

  return (
    <>
      <Controls.Button
        text="Create New Site"
        onClick={() => setOpen(true)}
      />
      <Modal
        isOpen={open}
        handleClose={handleClose}
        title="Create New Site"
      >
        <Form data-testid="createSiteForm">
          <Controls.Input
            name="name"
            label="Site Name"
            value={values.name}
            onChange={handleInputChange}
            required
          />
          <CustomerDropDown
            customers={customerData}
            values={values}
            handleInputChange={handleInputChange}
            required
          />
          <Controls.Button
            text="Submit"
            onClick={handleSubmit}
            disabled={!values.customerId || !values.name}
          />
        </Form>
      </Modal>
    </>
  );
}

CreateNewSiteButton.propTypes = {
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
