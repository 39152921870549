import React, {
  useMemo, useEffect, useRef,
} from 'react';

import PropTypes from 'prop-types';
import {
  useTheme, useMediaQuery, TableBody, CircularProgress, TableRow, TableCell,
} from '@mui/material';

import useTable from '../../components/useTable';
import useSiteSettings from '../../hooks/useSiteSettings';
import usePermission from '../../hooks/usePermissions';
import IntakeTableRow from './IntakeInfoTableRow';

import { useIntakeData } from '../../context/IntakeDataContext';

const makeHeader = (screenSize, showAppointmentField) => {
  if (screenSize === 'narrow') {
    return [
      { id: 'patientId', label: 'PATIENT ID' },
      { id: 'intakeStatus', label: 'TIME SENT' },
      { id: 'options', label: 'OPTIONS', disableSorting: true },
    ];
  }
  return [
    { id: 'patientId', label: 'PATIENT ID' },
    { id: 'createdAt', label: 'TIME SENT' },
    { id: 'createdBy', label: 'CREATED BY' },
    { id: 'intakeStatus', label: 'INTAKE STATUS' },
    ...(showAppointmentField ? [{ id: 'appointmentDate', label: 'APPOINTMENT' }] : []),
    { id: 'options', label: 'OPTIONS', disableSorting: true },
  ];
};

export default function IntakeInfoTable(props) {
  const {
    searchResults, setSuccessMessage, setErrorMessage, integrationSettings, showAppointmentField,
  } = props;
  const intervalIdRef = useRef(null);
  const theme = useTheme();
  const isNarrowScreen = useMediaQuery(theme.breakpoints.down('md'));

  const header = makeHeader(isNarrowScreen ? 'narrow' : 'wide', showAppointmentField);
  const { activeSiteId } = useSiteSettings();
  const { viewTraces, canReviewIntake } = usePermission();
  const canViewTraces = useMemo(
    () => viewTraces(),
    [viewTraces],
  );
  const intakeReviewAccess = canReviewIntake();

  const {
    data, count, loading, fetchIntakeData,
  } = useIntakeData();

  const {
    TblContainer,
    TblHead,
    TblPagination,
    page,
    setPage,
    rowsPerPage,
    allPageDataSorted,
  } = useTable(
    searchResults.length > 0 ? searchResults : data,
    header,
    { fn: (items) => items },
  );

  const resetFetchInterval = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      fetchIntakeData(page, rowsPerPage);
    }, 30000);
  };

  useEffect(() => {
    setPage(0);
    fetchIntakeData(0, rowsPerPage);
    resetFetchInterval(); // Set the initial interval

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [activeSiteId]);

  useEffect(() => {
    fetchIntakeData(page, rowsPerPage);
    // Reset the interval whenever page or rowsPerPage changes
    resetFetchInterval();
  }, [page, rowsPerPage]);

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody data-testid="intakeTable">
          {loading ? (
            <TableRow>
              <TableCell colSpan={header.length} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : data && allPageDataSorted().map((row) => (
            <IntakeTableRow
              key={row.hxId}
              row={row}
              header={header}
              activeSiteId={activeSiteId}
              canReviewIntake={intakeReviewAccess}
              canViewTraces={canViewTraces}
              integrationSettings={integrationSettings}
              isNarrowScreen={isNarrowScreen}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              showAppointmentField={showAppointmentField}
            />
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination
        count={count}
      />
    </>
  );
}

IntakeInfoTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  searchResults: PropTypes.array.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integrationSettings: PropTypes.object.isRequired,
  showAppointmentField: PropTypes.bool.isRequired,
};
