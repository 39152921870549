import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { htmlToText } from 'html-to-text';
import Toast, { useToast } from '../../components/useToast';
import { useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../../services/axios';
import '../../App.css';

export default function CopyReportButton({ mkey, activeSiteId, htmlContent }) {
  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();
  const apiClient = useApiClient();

  const options = {
    wordwrap: null,
    selectors: [
      {
        selector: 'p',
        options: {
          leadingLineBreaks: 1,
          trailingLineBreaks: 1,
        },
      },
      {
        selector: 'br',
        format: 'skip',
      },
    ],
  };

  const copyText = (text) => {
    const plainText = htmlToText(text, options);
    navigator.clipboard.writeText(plainText);
    toastSetSuccessNotification('Report copied to clipboard');
  };

  const fetchAndCopyText = () => {
    basicServiceHandler(
      () => apiClient.getIntakeReview(activeSiteId, mkey),
      (response) => {
        copyText(response.data.reportHtml);
      },
      (error) => {
        toastSetErrorNotification(
          axiosErrorToUserMessage(error) || 'An error occurred when fetching the report.',
        );
      },
    );
  };

  const onClick = () => {
    if (htmlContent) {
      copyText(htmlContent);
    } else {
      fetchAndCopyText();
    }
  };

  return (
    <>
      <Tooltip title="Copy report" arrow>
        <IconButton onClick={onClick} data-testid="copyIcon">
          <ClipboardDocumentListIcon className="table-tool-icon" />
        </IconButton>
      </Tooltip>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </>
  );
}

CopyReportButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  activeSiteId: PropTypes.string.isRequired,
  htmlContent: PropTypes.string,
};

CopyReportButton.defaultProps = {
  htmlContent: null,
};
