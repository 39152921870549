import React, { useState } from 'react';
import {
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import validator from 'validator';
import Form, { useForm } from '../components/useForm';
import { Controls } from '../components/controls/Controls';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
  axiosAuthInstance,
  axiosErrorToUserMessage,
} from '../services/axios';
import usePermission from '../hooks/usePermissions';
import { sendCreateAPIUserRequest, sendSignUpEmail } from '../services/auth/authServices';
import {
  RolesDropdown,
  RolesProvider,
  adminRole,
  apiUserRole,
} from '../components/RoleManager';
import { useApiClient } from '../context/ApiClientContext';
import { ApiUserConfirmationModal } from './CreateNewUser/ApiUserConfirmationModal';
import AllSitesOrCustomersDropDown from './CreateNewUser/AllSitesOrCustomersDropDown';

const initialFieldValues = {
  email: '',
  siteIds: [],
  roles: [],
  customerIds: [],
};

const API_USER_PASSWORD_LENGTH = 26;
export default function CreateNewUserForm(props) {
  const { setSuccessMessage, setErrorMessage } = props;
  const apiClient = useApiClient();
  const axiosAuth = useAxiosPrivate(axiosAuthInstance);
  const {
    isAdmin,
    isCustomerAdmin,
    hasAccuroAccess,
  } = usePermission();
  const [customerListEnabled, setCustomerListEnabled] = useState(false);
  const [apiUserModalOpen, setApiUserModalOpen] = useState(false);
  const [apiUserSelected, setApiUserSelected] = useState(false);
  const [apiUserCredentials, setApiUserCredentials] = useState({
    username: '',
    password: '',
  });
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
  } = useForm(initialFieldValues);
  const resetForm = () => {
    setValues(initialFieldValues);
    setCustomerListEnabled(false);
    setErrors({});
  };

  const isFHXAdmin = isAdmin();
  const isExternalAdmin = isCustomerAdmin();

  const inputs = [
    {
      name: 'email',
      label: apiUserSelected ? 'Username' : 'Email',
      value: values.email,
      onChange: handleInputChange,
      error: errors.email,
      dataTestId: 'emailField',
    },
  ];

  const validateUserInfo = () => {
    if (customerListEnabled) {
      errors.customerIds = values.customerIds.length > 0 ? '' : 'Please select at least one Customer';
    } else {
      errors.siteIds = values.siteIds.length > 0 ? '' : 'Please select at least one Site';
    }
    errors.roles = values.roles.length > 0
      ? ''
      : 'Please select at least one Role';
    // only validate username if it is email if user is not api user
    if (!values.roles.includes(apiUserRole.value)) {
      errors.email = validator.isEmail(values.email)
        ? ''
        : 'Please enter a valid email address';
    } else {
      errors.email = values.email.length > 0
        ? ''
        : 'Please enter username';
    }
    setErrors({ ...errors });
  };

  const handleRoleChange = (e) => {
    const selectedRoles = e.target.value;
    const customerAdminSelected = selectedRoles.includes(adminRole[1].value);
    setApiUserSelected(selectedRoles.includes(apiUserRole.value));
    handleInputChange(e);
    // reset customerId or siteId list
    setValues((prev) => ({
      ...prev,
      siteIds: [],
      customerIds: [],
    }));
    setCustomerListEnabled(customerAdminSelected);
    setErrors({});
  };

  const generateRandomPassword = (length) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join('');
  };

  const handleSingUpEmail = async () => {
    await sendSignUpEmail(axiosAuth, {
      username: values.email,
      siteIds: values.siteIds,
      roles: values.roles,
      customerIds: values.customerIds,
    });
    setSuccessMessage('Email sent successfully!');
  };

  const handleApiUserSignUp = async () => {
    const newPassword = generateRandomPassword(API_USER_PASSWORD_LENGTH);
    const response = await sendCreateAPIUserRequest(axiosAuth, {
      username: values.email,
      password: newPassword,
      siteIds: values.siteIds,
      roles: values.roles,
    });
    setSuccessMessage(response.message);
    setApiUserModalOpen(true);
    setApiUserCredentials({
      username: values.email,
      password: newPassword,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateUserInfo();
    if (errors.email || errors.customerIds || errors.roles || errors.siteIds) {
      setErrorMessage('Please fill out all the required fields');
      return;
    }

    try {
      if (values.roles.includes(apiUserRole.value)) {
        handleApiUserSignUp();
      } else {
        handleSingUpEmail();
      }
      resetForm();
    } catch (error) {
      setErrorMessage(
        axiosErrorToUserMessage(error) || 'An error occurred. Please try again.',
      );
    }
  };

  return (
    <RolesProvider>
      <Form onSubmit={handleSubmit}>
        <Grid container sx={{ display: 'flex' }}>
          <RolesDropdown
            isEditing
            isAdmin={isFHXAdmin}
            isCustomerAdmin={isExternalAdmin}
            hasAccuroAccess={hasAccuroAccess()}
            handleInputChange={handleRoleChange}
            name="roles"
            selectedRoles={values.roles}
            error={errors.roles}
          />
        </Grid>
        <Grid container sx={{ display: 'flex' }}>
          {inputs.map((field) => {
            const {
              name,
              label,
              value,
              onChange,
              error,
              type,
              dataTestId,
            } = field;
            return (
              <Controls.Input
                key={name}
                name={name}
                type={type}
                label={label}
                value={value}
                onChange={onChange}
                error={error}
                autoComplete={field.autoComplete}
                data-testid={dataTestId}
              />
            );
          })}
          {(isFHXAdmin || isExternalAdmin)
            && values.roles.includes('CustomerAdmin')
            && (
              <AllSitesOrCustomersDropDown
                disabled={!customerListEnabled}
                apiClient={apiClient}
                values={values}
                errors={errors}
                handleInputChange={handleInputChange}
                isCustomer
                isAdmin={isFHXAdmin}
              />
            )}
          <AllSitesOrCustomersDropDown
            disabled={customerListEnabled}
            apiClient={apiClient}
            values={values}
            errors={errors}
            handleInputChange={handleInputChange}
          />
          <ApiUserConfirmationModal
            isOpen={apiUserModalOpen}
            handleClose={() => { setApiUserModalOpen(false); }}
            credential={apiUserCredentials}
            setSuccessMessage={setSuccessMessage}
          />
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Controls.Button
            text="Create"
            type="submit"
            data-testid="createButton"
          />
        </Grid>
      </Form>
    </RolesProvider>
  );
}

CreateNewUserForm.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
