import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useToast } from '../../components/useToast';
import { Controls } from '../../components/controls/Controls';
import {
  axiosErrorToUserMessage,
} from '../../services/axios';

function ListDropDown({
  isCustomer,
  list,
  values,
  errors,
  handleInputChange,
  disabled,
}) {
  const details = {
    dataTestId: isCustomer ? 'CustomerDropDown' : 'SiteDropDown',
    label: isCustomer ? 'Customers' : 'Sites',
    labelId: isCustomer ? 'customer-ids-label' : 'site-ids-label',
    error: isCustomer ? errors.customerIds : errors.siteIds,
    id: isCustomer ? 'customer-ids' : 'site-ids',
    value: isCustomer ? values.customerIds : values.siteIds,
    name: isCustomer ? 'customerIds' : 'siteIds',
  };
  return (
    <Controls.Select
      disabled={disabled}
      data-testid={details.dataTestId}
      label={details.label}
      error={details.error}
      labelId={details.labelId}
      id={details.id}
      multiple
      value={details.value}
      name={details.name}
      onChange={handleInputChange}
      input={<OutlinedInput id="select-multiple-sites" label="Site Ids" />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected?.map((value) => (
            <Chip key={value} label={list.find((c) => c.id === value).name} />
          ))}
        </Box>
      )}
    >
      {list
      && list.length > 0
      && list.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Controls.Select>
  );
}

function AllSitesOrCustomersDropDown({
  values, errors, handleInputChange, isCustomer, apiClient, isAdmin, disabled,
}) {
  const [allInfo, setAllInfo] = useState([]);

  const { toastSetErrorNotification } = useToast();

  const fetchAllInfo = async () => {
    try {
      let list;
      if (isCustomer) {
        list = isAdmin ? await apiClient.getAllCustomers() : await apiClient.getAssignedCustomers();
      } else {
        list = await apiClient.getAllSite();
      }
      setAllInfo(list.data);
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error) || 'Error fetching all data.',
      );
    }
  };

  const getCustomerFromCustomerList = (customerId) => {
    const customer = allInfo.find((c) => c.id === customerId);
    return customer;
  };

  useEffect(() => {
    fetchAllInfo();
  }, []);

  const handleCustomerChange = (e) => {
    // fetch all siteIds associated with customer;
    const customerIdsSelected = e.target.value;
    const siteIds = customerIdsSelected.map((id) => {
      const customer = getCustomerFromCustomerList(id);
      return customer.sites.map((site) => site.id);
    }).flat();
    handleInputChange(e);
    handleInputChange({
      target: {
        name: 'siteIds',
        value: siteIds,
      },
    });
  };

  return (
    <ListDropDown
      disabled={disabled}
      isCustomer={isCustomer}
      list={allInfo}
      values={values}
      errors={errors}
      handleInputChange={isCustomer ? handleCustomerChange : handleInputChange}
    />
  );
}

export default AllSitesOrCustomersDropDown;

const sharedPropTypes = {
  values: PropTypes.shape({
    siteIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    siteIds: PropTypes.string,
  }),
  handleInputChange: PropTypes.func.isRequired,
};

ListDropDown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  ...sharedPropTypes,
};

AllSitesOrCustomersDropDown.propTypes = {
  ...sharedPropTypes,
};
