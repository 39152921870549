import React, { useState } from 'react';
import { FormGroup } from '@mui/material';
import { useTheme } from '@emotion/react';

// Custom hook for managing form state and handling input changes
export function useForm(initialFieldValues, onValuesChange) {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});

  // Handle input changes and update the form values state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues((prev) => ({ ...prev, [name]: value }));
    if (onValuesChange) {
      // Notify the parent component about value changes
      onValuesChange(newValues);
    }
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  };
}

export default function Form(props) {
  // eslint-disable-next-line react/prop-types
  const { children, groupsx, ...other } = props;
  const theme = useTheme();

  const sx = groupsx || {
    '& .MuiFormControl-root': {
      width: '100%',
      margin: theme.spacing(1),
    },
  };
  return (
    <form
      autoComplete="off"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <FormGroup
        sx={sx}
      >
        {children}
      </FormGroup>
    </form>
  );
}
