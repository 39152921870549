import { Collapse, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function CategoryButton(props) {
  const {
    category,
    handleItemClick,
    highlight,
    handleSubCategoryClick,
  } = props;
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const toggleSubCategoryVisibility = () => {
    setIsSubCategoryOpen((prev) => !prev);
  };

  if (!category.subCategories) {
    return (
      <ListItem
        onClick={() => {
          handleItemClick(category);
        }}
        style={{
          backgroundColor: highlight ? 'lightblue' : 'white',
        }}
      >
        <ListItemText primary={category.name} />
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        onClick={() => {
          setSelectedSubCategory(null);
          handleItemClick(category);
          toggleSubCategoryVisibility();
        }}
        style={{
          backgroundColor: highlight ? 'lightblue' : 'white',
        }}
      >
        <ListItemText primary={category.name} />
        {isSubCategoryOpen && highlight ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isSubCategoryOpen && highlight}>
        {category.subCategories.map((subCategory) => (
          <ListItem
            key={subCategory.id}
            sx={{ pl: 4 }}
            onClick={() => {
              handleSubCategoryClick(subCategory);
              setSelectedSubCategory(subCategory);
            }}
            style={{
              backgroundColor: highlight && selectedSubCategory?.id === subCategory.id ? 'lightblue' : 'white',
            }}
          >
            <ListItemText primary={subCategory.name} />
          </ListItem>
        ))}
      </Collapse>
    </>
  );
}

CategoryButton.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    subCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  handleItemClick: PropTypes.func.isRequired,
  highlight: PropTypes.bool.isRequired,
  handleSubCategoryClick: PropTypes.func.isRequired,
};
