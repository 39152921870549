import React from 'react';
import PropTypes from 'prop-types';
import TextFieldSetting from '../TextFieldSetting';
import ToggleButtonSetting from '../ToggleButtonSetting';
import ToggleSwitchSetting from '../ToggleSwitchSetting';
import AutoCompleteDropDownSetting from '../AutoCompleteDropDownSetting';
import LogoUploadSetting from '../LogoUploadSetting';

export default function SettingsInputSwitcher(props) {
  const {
    type,
    setting,
    setValues,
    refreshParent,
    setErrorMessage,
    setSuccessMessage,
    options,
    defaultOption,
    values,
    handleOptionChange,
    isCreateMode,
    contextType,
  } = props;
  switch (type) {
    case 'switch':
      return (
        <ToggleSwitchSetting
          setting={setting}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          defaultOption={defaultOption}
          contextType={contextType}
        />
      );
    case 'text':
      return (
        <TextFieldSetting
          setValues={setValues}
          setting={setting}
          refreshParent={refreshParent}
          isCreateMode={isCreateMode}
          contextType={contextType}
        />
      );
    case 'toggle':
      return (
        <ToggleButtonSetting
          setting={setting}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          options={options}
          defaultOption={defaultOption}
          contextType={contextType}
        />
      );
    case 'dropdown':
      return (
        <AutoCompleteDropDownSetting
          setting={setting}
          options={options}
          values={values}
          handleOptionChange={handleOptionChange}
          contextType={contextType}
        />
      );

    case 'logoUpload':
      return (
        <LogoUploadSetting
          setting={setting}
          setValues={setValues}
          refreshParent={refreshParent}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          contextType={contextType}
        />
      );
    default:
      return null;
  }
}

SettingsInputSwitcher.propTypes = {
  type: PropTypes.string.isRequired,
  setting: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contextType: PropTypes.oneOf(['site', 'customer']).isRequired,
  refreshParent: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setValues: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  defaultOption: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
    PropTypes.bool,
  ]),
  values: PropTypes.string,
  handleOptionChange: PropTypes.func,
  isCreateMode: PropTypes.bool,
};

SettingsInputSwitcher.defaultProps = {
  values: '',
  defaultOption: { value: '' },
  options: [{ name: '' }],
  refreshParent: () => {},
  setSuccessMessage: () => {},
  setErrorMessage: () => {},
  handleOptionChange: () => {},
  setValues: () => {},
  isCreateMode: false,
};
